const state = {
  freeMediaTypes: [],
};

const getters = {
  freeMediaTypes: state => _.uniq(state.freeMediaTypes),
  freeMediaTypesCount: (state, getters) => getters.freeMediaTypes.length,
};

const actions = {
  addFreeMediaType({ commit }, mediaType) {
    commit('addFreeMediaType', mediaType);
  },
  clearFreeMediaTypes({ commit }) {
    commit('clearFreeMediaTypes');
  },
};

const mutations = {
  addFreeMediaType(state, mediaType) {
    state.freeMediaTypes.push(mediaType);
  },
  clearFreeMediaTypes(state) {
    state.freeMediaTypes = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
