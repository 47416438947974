import cookies from 'js-cookie';
import * as appApi from '@motionelements/core/src/api/app.js';
import { initAccount, getInitialUiMode } from '@motionelements/core/src/services/account.service';
import { isMobile } from '@motionelements/core/src/helpers/browser.js';
import { getJwtPayload } from '@motionelements/core/src/services/jwt.service';

// vuex-persistence keys
import {
  COOKIE_CURRENCY_KEY,
  STORAGE_VUEX_CURRENCY_KEY,
  STORAGE_VUEX_CURRENCY_VERSION,
} from '@motionelements/core/src/app.constants.js';

// Nuxt compatibility workaround
let store;

// Nuxt compatibility workaround
export function setStore(s) {
  // if (!store) store = s;
  store = s;
  // setAccountServiceStore(store);
}

// async function listCurrencies() {
//   return appApi.listCurrencies();
// }

export async function listMediaTypes() {
  return appApi.listMediaTypes();
}

// const isPrerender = process.env.VUE_APP_PRERENDER === 'true' || (navigator.userAgent || '').indexOf('HeadlessChrome') !== -1;
const isPrerender = process.env.VUE_APP_PRERENDER === 'true' || ((typeof navigator !== 'undefined' && navigator.userAgent) || '').indexOf('HeadlessChrome') !== -1;

export function loadFonts(languageCode) {
  if (isPrerender) {
    return; // skip
  }

  if (['ja', 'zh-hant'].includes(languageCode)) {
    // load font only for chrome
    if (!_.get(window, 'chrome')) {
      console.log('loadFonts', 'not chrome, skip');
      return;
    }

    // from https://fonts.adobe.com/my_fonts#web_projects-section
    /* eslint-disable */
    (function (d) {
      var config = {
        kitId: 'kcr4dls',
        scriptTimeout: 3000,
        async: true,
      };
      var h = d.documentElement;
      var t = setTimeout(() => {
        h.className = `${h.className.replace(/\bwf-loading\b/g, '')} wf-inactive`;
      }, config.scriptTimeout);
      var tk = d.createElement('script');
      var f = false;
      var s = d.getElementsByTagName('script')[0];
      var a;
      h.className += ' wf-loading';
      tk.src = `https://use.typekit.net/${config.kitId}.js`;
      tk.async = true;
      tk.onload = tk.onreadystatechange = function () {
        a = this.readyState;
        if (f || (a && a != 'complete' && a != 'loaded')) return;
        f = true;
        clearTimeout(t);
        try {
          Typekit.load(config);
        } catch (e) {
        }
      };
      s.parentNode.insertBefore(tk, s);
    }(document));
    /* eslint-enable */
  } else if (languageCode === 'ko') {
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    const css = `
    @font-face {
      font-family: 'Nanum Gothic';
      font-style: normal;
      font-weight: 400;
      font-display: auto;
      src: local('NanumGothic'),
          url('https://dy43if2kkri7j.cloudfront.net/vendor/fonts/nanum-gothic-v17-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('https://dy43if2kkri7j.cloudfront.net/vendor/fonts/nanum-gothic-v17-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }

    /* nanum-gothic-700 - latin */
    @font-face {
      font-family: 'Nanum Gothic';
      font-style: normal;
      font-weight: 700;
      font-display: auto;
      src: local('NanumGothic Bold'), local('NanumGothic-Bold'),
          url('https://dy43if2kkri7j.cloudfront.net/vendor/fonts/nanum-gothic-v17-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
          url('https://dy43if2kkri7j.cloudfront.net/vendor/fonts/nanum-gothic-v17-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
    }`;
    style.type = 'text/css';
    style.rel = 'stylesheet';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);

    // use google api
    // const style = document.createElement('link');
    // style.type = 'text/css';
    // style.rel = 'stylesheet';
    // style.href = 'https://fonts.googleapis.com/css?family=Nanum+Gothic:400,700")';
    // style.async = true;
    // style.defer = true;
    // head.appendChild(style);
  }
}

export function detectBrowser() {
  if (process.env.VUE_APP_NUXT && !process.client) {
    return true;
  }

  store.commit('browser/setMobile', isMobile());

  return true;
}


const jwtPayload = getJwtPayload();

function getCurrencySession() {
  if (!process.server) {
    let currencySession = JSON.parse(sessionStorage.getItem(STORAGE_VUEX_CURRENCY_KEY));

    if (currencySession) {
      // const jwtId = `${user.jwt.id}|${user.language}|202102`,
      // const languageCode = store.getters['user/languageCode'];
      // const currencyCode = store.getters['user/currencyCode'];
      // let cookieCurrencyCode = cookies.get('ME_currency');
      let cookieCurrencyCode = _.get(jwtPayload, 'currency', null) || cookies.get(COOKIE_CURRENCY_KEY);
      cookieCurrencyCode = cookieCurrencyCode ? cookieCurrencyCode.toUpperCase() : null;
      const hash = `${store.state.user.jwt.id}|${store.state.user.language}|${cookieCurrencyCode}|${STORAGE_VUEX_CURRENCY_VERSION}`;
      if (hash !== currencySession.hash) {
        sessionStorage.removeItem(STORAGE_VUEX_CURRENCY_KEY);
        currencySession = null;
      }
      // if (languageCode !== currencySession.locale) {
      //   sessionStorage.removeItem('ME:currency');
      //   currencySession = null;
      // }

      return currencySession;
    }
  }
}

function initCurrencies() {
  // detectBrowser();
  const currencySession = getCurrencySession();
  // const currencyCookies = getCurrencyCookies();

  if (currencySession) { // currencyCookies
    store.commit('user/setCurrencies', currencySession.app.currencies);
    // store.dispatch('user/setDefaultCurrency', currencySession.defaultCurrency);
    // store.dispatch('user/setCurrencyCode', currencySession.user.currencyCode);
    // store.commit('site/setSupportedCurrencies', JSON.parse(sessionStorage.getItem('listCurrenciesData')));
  } else {
    // eslint-disable-next-line no-unused-vars
    store.dispatch('user/getCurrencies').then((response) => {
      /*
      const data = response.data.data;
      sessionStorage.setItem('listCurrenciesData', JSON.stringify(data));
      // update user currency
      // if USD is not inside, default user currency to default
      const defaultCurrency = data.find(obj => obj.default === true);
      if (defaultCurrency) {
        console.log('DEFAULT CURRENCY:', defaultCurrency);
        store.dispatch('user/setDefaultCurrency', defaultCurrency.code);

        // user does not have default currency set yet
        if (!store.state.user.currencyCode) {
          store.dispatch('user/setCurrencyCode', defaultCurrency.code);
        }
      }
      // else {
      //   // default to first currency in list
      //   if (!store.state.user.currencyCode) {
      //     store.dispatch('user/setCurrencyCode', data[0].code);
      //   }
      // }
      store.commit('site/setSupportedCurrencies', data);
       */
    });
  }
}

function initUi() {
  const mode = getInitialUiMode();
  store.commit('user/setUiMode', mode);
  if (mode === 'dark') {
    document.documentElement.classList.add('ui-dark');
  } else {
    document.documentElement.classList.remove('ui-dark');
  }
}

// init by API or localStorage
// can move to Vuex
export async function bootstrapApp(vm) {
  detectBrowser();

  initUi();

  // set site cookie to vuex
  const siteCookies = cookies.get();

  if (siteCookies) {
    store.dispatch('cookie/setCookies', siteCookies);
  }

  // set ui mode
  const promise = initAccount(vm);

  if (promise instanceof Promise) {
    // eslint-disable-next-line no-unused-vars
    promise.then((response) => {
      // init currencies after member language is set
      initCurrencies();
    });
  } else {
    // not member, init currencies immediately
    initCurrencies();
  }
}

export function listMediatypesSeoName() {
  return appApi.listMediatypesSeoName();
}
