//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { getChannel } from '@/helpers/channels.js';

export default {
  name: 'ChannelsHero',
  components: {
    BaseImg: () => import('@motionelements/core/src/components/ui/BaseImg.vue'),
    HeroSearchBar: () => import('../core/search/HeroSearchBar.vue'),
  },
  computed: {
    ...mapState({
      channelType: state => state.channels.channelType,
      uiMode: state => state.user.preferences.ui.mode,
    }),
    currentChannel() {
      return getChannel(this, this.channelType);
    },
    pageHeadingMobile() {
      return this.$term('mediaType', this.channelType).wbr().plural().html();
    },
    pageHeadingDesktop() {
      return _.get(getChannel(this, this.channelType), 'headingHtml') || this.$term('mediaType', this.channelType).wbr().seo().plural()
.html();
    },
    channelBg() {
      const bgMode = (this.uiMode === 'dark')
        ? 'linear-gradient(0.69deg, rgb(1, 3, 20) 45.15%, rgba(1, 3, 20, 0) 73.93%)'
        : 'linear-gradient(0.69deg, #fff 45.15%, rgba(255, 255, 255, 0) 73.93%)';
      return {
        background: `${bgMode}, ${_.get(this.currentChannel, 'bg')}`,
      };
    },
    channelLeftBg() {
      const leftBg = _.get(this.currentChannel, 'leftBg');
      return leftBg ? {
        top: leftBg.top,
        transform: `translateX(calc(-100% - ${leftBg.transformX}))`,
      } : false;
    },
    channelRightBg() {
      const rightBg = _.get(this.currentChannel, 'rightBg');
      return rightBg ? {
        top: rightBg.top,
        transform: `translateX(${rightBg.transformX})`,
      } : false;
    },
  },
};
