//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'root-page',
  components: {
    BaseLayout: () => import('../components/core/BaseLayout.vue'),
    TheNavBarMinimal: () => import('@motionelements/core/src/components/nav/TheNavBarMinimal.vue'),
  },
};
