import api from './base-api-v2';

export const listSubscriptions = async (payload) => {
  const request = await api.request('get', '/v2/subscriptions', {
    params: {
      ...payload,
      ...api.getLocaleParams(),
    },
  });
  return request;
};

export const updateSubscription = (id, payload) => api.request('patch', `/v2/subscriptions/${id}`, {
  data: payload,
});

export const cancelSubscription = (id) => api.request('delete', `/v2/subscriptions/${id}`);
