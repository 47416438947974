import api from '@motionelements/core/src/api/base-api-v2';

const baseUrl = '/v2/favorites';

// eslint-disable-next-line
export const addFavoriteItem = (data) => {
  return api.request('post', `${baseUrl}/items`, {
    params: api.getLocaleParams(),
    data, // { sku: String }
  });
};

// eslint-disable-next-line
export const getFavoritesInfo = (axiosConfig) => {
  return api.get(baseUrl, {
    params: {
      ...api.getLocaleParams(),
    },
    ...(axiosConfig || {}),
  })
    .then(res => res.data);
  // .catch((err) => {
  //   console.error(err);
  // });
};

// eslint-disable-next-line
export const deleteFavoriteItems = (data) => {
  return api.request('delete', `${baseUrl}/items`, {
    params: api.getLocaleParams(),
    data, // { sku: String || skus: Array }
  });
};

// eslint-disable-next-line
export const listAllFavoriteItems = (params, axiosConfig) => {
  return api.get(`${baseUrl}/items`, {
    params: {
      ...params,
      ...api.getLocaleParams(),
    },
    ...(axiosConfig || {}),
  });
};
