//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'root-page',
  components: {
    BaseLayout: () => import('../components/core/BaseLayout.vue'),
    NavBar: () => import('../components/core/TheNavBar.vue'),
  },
  computed: {
    isTransparentNavbar() {
      const transparentNavbarPages = ['subscribe', 'campaign'];
      return transparentNavbarPages.some(page => this.$route.name?.includes(page));
    },
  },
};
