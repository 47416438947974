import { render, staticRenderFns } from "./channel.vue?vue&type=template&id=3475bbff&"
import script from "./channel.vue?vue&type=script&lang=js&"
export * from "./channel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChannelsHero: require('/opt/render/project/src/channels2022/src/components/channels/ChannelsHero.vue').default,HomeBenefits: require('/opt/render/project/src/channels2022/src/components/home-page/HomeBenefits.vue').default,HomeFaqs: require('/opt/render/project/src/channels2022/src/components/home-page/HomeFaqs.vue').default})
