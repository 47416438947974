// import { isBefore } from 'date-fns';
import { getJwtPayload } from '@motionelements/core/src/services/jwt.service';

const isProduction = process.env.VUE_APP_ENV === 'production';
const isPrerender = process.env.VUE_APP_PRERENDER === 'true';

const { internal: isInternal } = getJwtPayload();

export default {
    methods: {
        isFeatureEnabled(feature) {
            switch (feature) {
                case 'personalized_navbar':
                case 'library_download':
                case 'elastic_search_2020_01':
                case 'disable_paypal_for_twd':
                case 'contributor_new_media_types':
                case 'monthly_subscription':
                case 'midjourney_prompt':
                case 'buyer_new_media_types':
                case 'buyer_midjourney_prompt':
                case 'change_license':
                case 'corporate_redesign_2023':
                case 'image_prompt_channel':
                case 'image_prompt_contributor':
                case 'account_redesign_2023': // release 2024-03
                case 'account_redesign_settings_2023':
                case 'account_redesign_purchase_2023':
                case 'subscription_checkout_payment_methods_redesign_2023': // MP-6014
                case 'studio_ai_style_transfer_custom':
                case 'studio_ai':
                case 'flag_product':
                    return true;
                // case 'studio_ai':
                //     return isInternal;
                case 'send_for_review':
                    return !isProduction || isInternal;
                case 'media_player':
                case 'music_new_categories':
                case 'regenerate_previews':
                case 'sort_popular_local':
                case 'new_paypal':
                case 'search_result_scores':
                case 'payment_success_2024':
                // case 'flag_product':
                // case 'login_signup_redesign_2023':
                // case 'transitioned_products':
                    return !isProduction;
                case 'cancelling_subscription_feedback':
                case 'audio_stems':
                case 'artist_profile_picture_edit':
                case 'settings_tab_for_contributor':
                case 'artist_element_counts': // MP-2095
                case 'gcp_promo_new_ui':
                case 'deprecate_collections':
                case 'contributor_report_views':
                case 'contributor_report_favorites':
                case 'unlimited_subscription_header':
                case 'contributor_redesign_2023':
                case 'contributor-sidebar-no-products':
                case 'meilisearch':
                case 'exclude_ai_generated':
                case 'library_deleted_items':
                case 'transitioned_products':
                case 'login_signup_redesign_2023':
                case 'hide_image_similar_model_search_results':
                    return true;
                case 'tw_vat_invoice':
                case 'upload_vector_preview':
                case 'auth-modals':
                case 'set_default_payment_method':
                case 'facebook_share':
                case 'google_login_signup':
                case 'delete_contributor_account_2024':
                    return false;
                case 'subscription_new_price_jp':
                    return true;
                    // enable from April 1, 2024
                    // return !isProduction || isBefore(new Date(2024, 3, 1), new Date());
                case 'recaptcha':
                    return !isPrerender && (isProduction || ['staging'].includes(process.env.VUE_APP_ENV));
                // case 'contributor_report_views':
                // case 'contributor_report_favorites':
                //     return !isProduction || isInternal; // es issue 2022-08-12
                    // return !isProduction || this.$i18n.locale === 'zh-hant' || isInternal;
                case 'quote':
                case 'credits_purchase':
                default:
                    return false;
            }
        },
    },
};
