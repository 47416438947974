import * as favoritesApi from '@motionelements/core/src/api/favorites-core.js';

const state = {
  defaultFolder: {
    totalCount: 0,
  },
  addedToday: [],
};

const actions = {
  async addFavoriteItem({ commit }, { productId }) {
    return favoritesApi.addFavoriteItem({ sku: productId })
      .then((response) => {
        if (_.has(response, 'data.data.totalCount')) {
          commit('setDefaultFolderTotalCount', response.data.data.totalCount);
        }
        return response;
      });
  },
};

const mutations = {
  setDefaultFolderTotalCount: (state, count) => {
    state.defaultFolder.totalCount = count;
  },
  updateAddedToday(state, element) {
    const index = state.addedToday.findIndex(x => x.id === element.id);
    if (index < 0) state.addedToday.push(element);
  },
  findAndRemoveFavoriteItemsFromAddedToday(state, removedItemIds) {
    removedItemIds.forEach(removedItemId => {
      const index = state.addedToday.map(x => x.id).indexOf(removedItemId);
      if (index > -1) state.addedToday.splice(index, 1);
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
