import api from '@motionelements/core/src/api/base-api-v2.js';

import { snakeCaseKeys } from '@motionelements/core/src/helpers/string.js';

// eslint-disable-next-line import/prefer-default-export
export const listSearchTypeaheadSuggestions = async (params) => {
  const request = await api.get('/v2/suggest', {
    withCredentials: false,
    params: {
      ...api.getLocaleParams(),
      ...snakeCaseKeys(params),
    },
  });
  return request;
};
