import {
  STORAGE_VUEX_SEARCH_BOX_KEY,
  STORAGE_VUEX_SEARCH_BOX_VERSION,
} from '@motionelements/core/src/app.constants';
import VuexPersistence from 'vuex-persist';

export default new VuexPersistence({
  key: STORAGE_VUEX_SEARCH_BOX_KEY,
  storage: window.localStorage,
  reducer: ({ user, searchBox }) => ({
    hash: `${user.jwt.id}|${STORAGE_VUEX_SEARCH_BOX_VERSION}`,
    searchBox: {
      history: searchBox.history,
    },
    modified_at: Math.round(Date.now() / 1000),
  }),
  filter: (mutation) => [
    'searchBox/addHistory',
    'searchBox/deleteItem',
    'searchBox/deleteAll',
  ].includes(mutation.type),
});
