// sync player status from search/helpers/player/video.js
const state = {

  elementId: '',
  playerStatus: {
    ready: false,
    playing: false,
    pause: false,
    muted: false,
  },

};

const getters = {
  elementId: state => state.elementId,
  playerStatus: state => state.playerStatus,
};

const actions = {
};

const mutations = {
  setPlayerId(state, id) {
    state.elementId = id;
  },
  setStatus(state, { type, bool }) {
    console.log('[store/videoPlayer] setStatus :', type, bool);
    state.playerStatus[type] = bool;

    switch (true) {
      case type === 'playing' && bool:
        state.playerStatus.pause = false;
        break;
      case type === 'pause' && bool:
        state.playerStatus.playing = false;
        break;
      default:
        break;
    }
    console.log('[store/videoPlayer] setStatus :', state.playerStatus);
  },
  reset(state) {
    state.elementId = '';
    state.playerStatus = {
      id: '',
      ready: false,
      playing: false,
      pause: false,
      muted: false,
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
