import api, { setStore as setApiStore } from '@motionelements/core/src/api/base-api-v2';
// import * as accountService from '@motionelements/core/src/services/account.service.js';
// import * as appService from '@motionelements/core/src/services/app.service.js';
// import * as eventService from '@motionelements/core/src/services/event-bus.service.js';
// import * as notificationService from '@motionelements/core/src/services/notification.service.js';

import { setStore as setAccountServiceStore } from '@motionelements/core/src/services/account.service.js';
import { setStore as setAppServiceStore } from '@motionelements/core/src/services/app.service.js';
import { setStore as setLanguageServiceStore } from '@motionelements/core/src/services/language.service.js';
// import { setStore as setEventServiceStore } from '@motionelements/core/src/services/event-bus.service.js';
import { setStore as setNotificationServiceStore } from '@motionelements/core/src/services/notification.service.js';
// import { setStore as setApiStore } from '@motionelements/core/src/api/base-api-v2';
import meHelper from '@motionelements/helper';

export default function ({ app, $gtm }) {
  // Create a custom axios instance
  // const api = $axios.create({
  //   headers: {
  //     common: {
  //       Accept: 'text/plain, */*'
  //     }
  //   }
  // })
  //
  // // Set baseURL to something different
  // api.setBaseURL('https://api.motionelements.com');

  // api.setLocale('koo');

  // console.log('apicontext', app.store.$i18n.locale);

  // console.log('apiLocale', api.getLocaleParams());

  // accountService.setStore(app.store)
  // appService.setStore(app.store)
  // eventService.setStore(app.store)
  // notificationService.setStore(app.store)

  setAccountServiceStore(app.store);
  setAppServiceStore(app.store);
  setLanguageServiceStore(app.store);
  // setEventServiceStore(app.store);
  setNotificationServiceStore(app.store);
  setApiStore(app.store);
  $gtm.init(process.env.VUE_APP_GTM_ID);
  api.setLanguage(app.store.$i18n.locale);
  app.store.commit('user/setLanguage', app.store.$i18n.locale);
  meHelper.defaultLang.set(app.store.$i18n.locale);

  // Inject to context as $api
  // inject('apii', api);
}
