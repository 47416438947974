import cookies from 'js-cookie';
import meHelper from '@motionelements/helper';
import api from '@motionelements/core/src/api/base-api-v2';
import * as accountApi from '@motionelements/core/src/api/account.js';
import { setLocale as setLinkLocale } from '@motionelements/core/src/helpers/link.js';
import { setLocale as setDateLocale } from '@motionelements/core/src/helpers/date.js';
import { getJwtPayload } from '@motionelements/core/src/services/jwt.service.js';
import { getCookieLanguageCode, isLoggedIn } from '@motionelements/core/src/services/account.service.js';
import { COOKIE_LANGUAGE_KEY, STORAGE_VUEX_CURRENCY_KEY } from '@motionelements/core/src/app.constants.js';

let store;
let jwtPayload;
const validLanguageCodes = process.env.VUE_APP_LANGUAGE_CODES ? process.env.VUE_APP_LANGUAGE_CODES.split(',') : [];

const setCookie = (cookieKey, value) => {
  cookies.set(cookieKey, value, { domain: '.motionelements.com', secure: true });
  if (process.env.NODE_ENV === 'development') {
    cookies.set(cookieKey, value); // localhost fallback
  }
};

const setStore = (s) => {
  store = s;
};

const replacePathLanguageCode = (path, languageCode) => {
  // remove language from path
  console.log('path1 ', path);
  path = path.replace(new RegExp('^/([a-z]{2}|zh-hant|zh-hans)/'), '/');
  // path = path.replace(new RegExp('^\/(zh-hant|[a-z]{2})\/?'), '/');
  console.log('path2 ', path);
  return languageCode !== 'en' ? `/${languageCode}${path}` : path;
};

const redirectToLanguageUrl = (languageCode) => {
  if (store.state.user.language === languageCode) {
    store.dispatch('blocker/hide');
    return;
  }
  // update session store
  store.commit('user/setLanguage', languageCode);
  let path = store.state.route.fullPath;
  path = replacePathLanguageCode(path, languageCode);
  // window.location.replace(path);
  return path;
};

// called from vuex
// handle non vuex related functions
const changeLanguage = (languageCode) => {
  api.setLanguage(languageCode);
  if (!process.server) {
    setCookie(COOKIE_LANGUAGE_KEY, languageCode);
    // clear app / currencies session data
    sessionStorage.removeItem(STORAGE_VUEX_CURRENCY_KEY);
  }

  if (isLoggedIn()) {
    // update lazily
    return accountApi.updateAccount({
      language: languageCode,
    })
      .then(() => redirectToLanguageUrl(languageCode));
    // .then((response) => {
    //   // this.$root.$emit('updateGoogleTagManager', response.data.data);
    //   return response;
    // });
  }
  return Promise.resolve(redirectToLanguageUrl(languageCode));
};

const refreshJwtLanguage = (languageCode) => {
  let refreshJwtLanguageRetries = 0;
  if (process.env.VUE_APP_ENV === 'development') {
    // cannot set localhost cookie
    return false;
  }

  if (isLoggedIn()
    // don't return more than 3 times
    && refreshJwtLanguageRetries < 3) {
    try {
      accountApi.refreshJwt().then(() => {
        // new JWT
        jwtPayload = getJwtPayload();
        // console.log('LANG new payload', jwtPayload);
        // verify that new JWT has same language as data.language passed in
        if (_.get(jwtPayload, 'language') === languageCode) {
          redirectToLanguageUrl(languageCode);
        }
      });
    } catch (e) {
      console.error(e);
    } finally {
      refreshJwtLanguageRetries += 1;
    }
  }
};

const setLanguage = (languageCode, { to, from }, i18n) => {
  // sync lang to meHelper for usage
  meHelper.defaultLang.set(languageCode);
  // defaultLang.set(language);
  // set the current language for vuex-i18n. note that translation data
  // for the language might need to be loaded first
  i18n.locale = languageCode;
  store.dispatch('user/setLanguage', languageCode);
  setLinkLocale(languageCode);
  setDateLocale(languageCode);
  store.dispatch('route/setRoute', to);
  store.dispatch('route/setPreviousRoute', from);
};

const commonLanguageLogic = ({ to, from }, preLoadedLanguages, i18n) => {
  let languageCode;

  const cookieLanguageCode = getCookieLanguageCode();
  const languageFromParams = to.params.lang ? to.params.lang : 'en';
  const browserLanguage = navigator.language;

  function isLanguageValid(lang) {
    return validLanguageCodes.includes(lang);
  }

  function needToRedirect(lang) {
    return languageFromParams !== lang;
  }

  function redirect(lang) {
    // console.log('REDIRECT TO:', lang);
    const correctPath = replacePathLanguageCode(to.fullPath, lang);
    // console.log(correctPath);
    window.location.replace(correctPath);
  }

  // console.log('cookieLanguageCode: ', cookieLanguageCode);
  // console.log('languageFromParams: ', languageFromParams);
  // console.log('browserLanguage: ', browserLanguage);
  // console.log('validLanguageCodes: ', validLanguageCodes);

  if (cookieLanguageCode && isLanguageValid(cookieLanguageCode)) {
    languageCode = cookieLanguageCode;
    if (needToRedirect(cookieLanguageCode)) {
      redirect(languageCode);
    }
  } else if (isLanguageValid(languageFromParams)) {
    languageCode = languageFromParams;
  } else if (isLanguageValid(browserLanguage)) {
    languageCode = browserLanguage;
    if (needToRedirect(browserLanguage)) {
      redirect(languageCode);
    }
  } else {
    languageCode = 'en';
    if (needToRedirect('en')) {
      redirect(languageCode);
    }
  }

  // return languageCode;
  console.log(languageCode, preLoadedLanguages);
  setLanguage(languageCode, { to, from }, i18n);
  if (preLoadedLanguages.includes(languageCode)) {
    // setLanguage(languageCode, { to, from }, i18n);
    store.dispatch('blocker/hide');
    return Promise.resolve(null);
  }
  return Promise.resolve(languageCode);
};

const commonLanguageLogicNuxt = ({ to }, i18n) => {
  let languageCode;

  const cookieLanguageCode = getCookieLanguageCode();
  const languageFromParams = i18n.locale;
  const browserLanguage = navigator.language;

  function isLanguageValid(lang) {
    return validLanguageCodes.includes(lang);
  }

  function needToRedirect(lang) {
    return languageFromParams !== lang;
  }

  function redirect(lang) {
    // console.log('REDIRECT TO:', lang);
    const correctPath = replacePathLanguageCode(to.fullPath, lang);
    console.log('redirect(lang)', correctPath);
    window.location.replace(correctPath);
    return Promise.resolve(null);
  }

  if (cookieLanguageCode && isLanguageValid(cookieLanguageCode)) {
    languageCode = cookieLanguageCode;
    if (needToRedirect(cookieLanguageCode)) {
      return redirect(languageCode);
    }
  } else if (isLanguageValid(languageFromParams)) {
    languageCode = languageFromParams;
  } else if (isLanguageValid(browserLanguage)) {
    languageCode = browserLanguage;
    if (needToRedirect(browserLanguage)) {
      return redirect(languageCode);
    }
  } else {
    languageCode = 'en';
    if (needToRedirect('en')) {
      return redirect(languageCode);
    }
  }

  return Promise.resolve(languageCode);
};

const updateLanguageMessages = ({ to, from }, languageCode, messages, preLoadedLanguages, i18n) => {
  preLoadedLanguages.push(languageCode);
  console.log(languageCode, ' language LOADED');
  store.dispatch('blocker/hide');
  i18n.setLocaleMessage(languageCode, messages);
  setLanguage(languageCode, { to, from }, i18n);
};

export {
  setStore,
  setCookie,
  setLanguage,
  changeLanguage,
  refreshJwtLanguage,
  commonLanguageLogic,
  commonLanguageLogicNuxt,
  redirectToLanguageUrl,
  replacePathLanguageCode,
  updateLanguageMessages,
};
