const state = {
  currentSound: null,
  playStatus: '',
  sounds: [],
  soundsLimit: 1000,
  // @deprecated start
  isInit: false,
  element: {
    id: false,
  },
  playProgress: {
    timeElapsed: 0,
    timeTotal: 0,
    timeEstimated: 0,
  },
  waveformWidth: 0,
  // @deprecated end
};

const getters = {
  playStatus: state => state.playStatus,
  currentSound: state => state.currentSound,
  currentSoundId: state => (state.currentSound ? state.currentSound.id : ''),
  sounds: state => state.sounds,
  // @deprecated start
  playElementId: state => state.element.id,
  playProgress: state => state.playProgress,
  waveformWidth: state => state.waveformWidth,
  element: state => state.element,
  // @deprecated end
};

const actions = {

  reset({ commit }) {
    commit('reset');
  },

  setCurrentSound({ commit }, sound) {
    commit('setCurrentSound', sound);
  },

  setPlayStatus({ commit }, status) {
    commit('setPlayStatus', status);
  },

  setSoundProgress({ commit }, progressObj) {
    commit('setSoundProgress', progressObj);
  },

  // @deprecated start
  setInitStatus({ commit }, bool) {
    commit('setInitStatus', bool);
  },

  setPlayProgress({ commit }, status) {
    commit('setPlayProgress', status);
  },

  setWaveformWidth({ commit }, width) {
    commit('setWaveformWidth', width);
  },

  setElementInfo({ commit }, element) {
    commit('setElementInfo', element);
  },
  // @deprecated end
};

const mutations = {

  setPlayStatus(state, status) {
    state.playStatus = status;
  },

  setCurrentSound(state, sound) {
    state.currentSound = sound;
  },

  setSoundProgress(state, progressObj) {
    // filter same id item
    const sounds = state.sounds.filter(x => x.id !== progressObj.id);
    sounds.push(progressObj);
    // FIFO
    if (sounds.length > state.soundsLimit) {
      sounds.shift();
    }
    state.sounds = sounds;
  },

  reset(state) {
    state.playStatus = '';
    state.currentSound = null;
    // state.sounds = [];
  },

  // @deprecated start
  setInitStatus(state, status) {
    state.isInit = status;
  },

  setPlayProgress(state, progress) {
    state.playProgress = progress;
  },

  setWaveformWidth(state, width) {
    state.waveformWidth = width;
  },

  setElementInfo(state, element) {
    state.element = element;
  },
  // @deprecated end
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
