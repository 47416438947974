// need install vue-i18n to use
export default function (string) {

  return this.$i18n && ['ja', 'zh-hant', 'zh-hans'].includes(this.$i18n.locale)
      ? `wbr.${string}`
      : string;

  // if (this.$i18n.locale === 'ja') {
  //   // console.log('wbr match ja! return wbr.', string);
  //   return this.$t(`wbr.${string}`);
  // }
  // // console.log('wbr return usual! ', string);
  // return this.$t(string);
};
