import { listCountries } from '@motionelements/core/src/api/app.js';
import allLanguages from '@motionelements/core/src/allLanguages.json';

const supportedLanguageCodes = process.env.VUE_APP_LANGUAGE_CODES ? process.env.VUE_APP_LANGUAGE_CODES.split(',') : [];

const navbarChannelInfo = [
  {
    id: 'video',
    link: '/video',
    icon: 'video',
    label: 'video',
  },
  {
    id: 'music',
    link: '/music',
    icon: 'music',
    label: 'music',
  },
  {
    id: 'after-effects-templates',
    link: '/after-effects-templates',
    icon: 'after-effects-square',
    label: 'ae',
  },
  {
    id: 'premiere-pro-templates',
    link: '/premiere-pro-templates',
    icon: 'premiere-pro',
    label: 'pr',
  },
  {
    id: 'apple-motion-templates',
    link: '/apple-motion-templates',
    icon: 'apple-motion',
    label: 'motion',
  },
  {
    id: 'davinci-resolve-templates',
    link: '/davinci-resolve-templates',
    icon: 'davinci-resolve',
    label: 'resolve',
  },
  {
    id: 'stock-image',
    link: '/stock-image',
    icon: 'image',
    label: 'image',
  },
  {
    id: 'sound-effects',
    link: '/sound-effects',
    icon: 'sfx',
    label: 'sfx',
  },
  {
    id: '3d',
    link: '/3d',
    icon: '3d-model',
    label: '3d',
  },
  {
    id: 'lottie',
    link: '/lottie',
    icon: 'lottie',
    label: 'lottie',
  },
];

const state = {
  // isLoading: false,
  // currencies: [],
  countries: [],
  mediaTypeGroupCodes: ['video', 'music', 'sfx', 'image', '3d', 'lottie', 'graphic', 'midjourney_prompt', 'ae', 'pr', 'fcp', 'resolve', 'ui', 'presentation', 'notion'],
  languages: allLanguages.filter(x => supportedLanguageCodes.includes(x.code)),
  isMobileAccountSidebarActive: false,
  // navbar preference
  navbarRecentlyVisited: [],
  navbarChannelList: navbarChannelInfo,
};

const getters = {
  // defaultCurrencyCode: state => _.get(state.currencies.find(x => x.default === true), 'code') || 'USD',
  // getCurrencyByCode: (state) => (code) => state.currencies.find(x => x.code === code),
  getLanguageByCode: (state) => (code) => state.languages.find(x => x.code === code),
  getCountryByCode: (state) => (code) => state.countries.find(x => x.code === code),
};

const actions = {
  listCountries({ commit, state }) {
    if (Array.isArray(state.countries) && state.countries.length > 0) {
      return Promise.resolve();
    }

    return listCountries().then((response) => {
      commit('setCountries', response.data.data);
      return response;
    });
  },
  /*
  getCurrencies({ commit }) {
    listCurrencies().then((response) => {
      // sessionStorage.setItem('listCurrenciesData', JSON.stringify(data));
      // update user currency
      // if USD is not inside, default user currency to default
      // const defaultCurrency = data.find(obj => obj.default === true);
      // if (defaultCurrency) {
      //   console.log('DEFAULT CURRENCY:', defaultCurrency);
      //   store.dispatch('user/setDefaultCurrency', defaultCurrency.code);
      //
      //   // user does not have default currency set yet
      //   if (!store.state.user.currencyCode) {
      //     store.dispatch('user/setCurrencyCode', defaultCurrency.code);
      //   }
      // }
      // else {
      //   // default to first currency in list
      //   if (!store.state.user.currencyCode) {
      //     store.dispatch('user/setCurrencyCode', data[0].code);
      //   }
      // }
      commit('setCurrencies', response.data.data);
      return response;
    });
  },
  */
};

const mutations = {
  // setLoading: (state, bool) => {
  //   state.isLoading = bool;
  // },
  // setCurrencies: (state, data) => {
  //   state.currencies = Object.freeze(data);
  // },
  setCountries: (state, data) => {
    state.countries = Object.freeze(data);
  },
  setMediaTypes: (state, data) => {
    state.mediaTypes = Object.freeze(data);
  },
  setMobileAccountSidebarActive: (state, active) => {
    state.isMobileAccountSidebarActive = active;
  },
  setNavbarRecentlyVisited: (state, list) => {
    state.navbarRecentlyVisited = Object.freeze(list);
  },
  addNavbarRecentlyVisited(state, channel) {
    const index = state.navbarRecentlyVisited.map(x => x.id).indexOf(channel.id);
    if (index < 0) {
      state.navbarRecentlyVisited.unshift(channel);
    }
    if (index > 2) {
      state.navbarRecentlyVisited.splice(index, 1);
      state.navbarRecentlyVisited.unshift(channel);
    }
    if (state.navbarRecentlyVisited.length > 5) {
      state.navbarRecentlyVisited = state.navbarRecentlyVisited.splice(0, 5);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
