import { listFaqs } from '@motionelements/core/src/api/faq.js';

const state = () => ({
  contributorFaqs: [],
  downloadFaqs: [],
  creditsFaqs: [],
  subscriptionFaqs: [],
  loading: false,
});

const getters = {
  // eslint-disable-next-line prefer-spread
  subscriptionFaqs: (state) => [].concat.apply([], state.subscriptionFaqs.map(group => group.questions)),
};

const actions = {
  LIST_FAQS({ commit }, type) {
    commit('setLoading', true);
    return listFaqs(type)
      .then((response) => {
        commit('setFaqs', { type, faqs: response.data.data });
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        commit('setLoading', false);
      });
  },
};

const mutations = {
  setFaqs(state, { type, faqs }) {
    switch (type) {
      case 'contributor':
        state.contributorFaqs = faqs;
        break;
      case 'download':
        state.downloadFaqs = faqs;
        break;
      case 'credits':
        state.creditsFaqs = faqs;
        break;
      case 'subscription':
        state.subscriptionFaqs = faqs;
        break;
      // no default
    }
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};


