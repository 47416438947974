import VuexPersistence from 'vuex-persist';
import { STORAGE_VUEX_EXPERIMENT_KEY } from '@motionelements/core/src/app.constants.js';

export default new VuexPersistence({
  key: STORAGE_VUEX_EXPERIMENT_KEY,
  storage: window.sessionStorage,
  reducer: ({ experiment }) => ({
    experiment: {
      experiments: experiment.experiments,
    },
  }),
  filter: (mutation) => [
    'experiment/setExperimentVariant',
  ].includes(mutation.type),
});
