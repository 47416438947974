import createMutationsSharer from 'vuex-shared-mutations';

import vuexSharedMutations from '@motionelements/core/src/helpers/vuexSharedMutations.js';

// core localStorage
import persistNavbar from '@motionelements/core/src/store/persists/localStorage/navbar.vuex.persistence.js';
import persistSearchBox from '@motionelements/core/src/store/persists/localStorage/searchBox.vuex.persistence.js';

// core sessionStorage
import persistExperiment from '@motionelements/core/src/store/persists/sessionStorage/experiment.vuex.persistence.js';
import persistCurrency from '@motionelements/core/src/store/persists/sessionStorage/currency.vuex.persistence.js';
import persistCart from '@motionelements/core/src/store/persists/sessionStorage/cart.vuex.persistence.js';
import persistFavorites from '@motionelements/core/src/store/persists/sessionStorage/favorites.vuex.persistence.js';

const isProduction = process.env.VUE_APP_ENV === 'production';

export default ({ store }) => {
  persistExperiment.plugin(store);
  persistCart.plugin(store);
  persistNavbar.plugin(store);
  persistSearchBox.plugin(store);
  persistFavorites.plugin(store);
  createMutationsSharer({
    predicate: [
      ...vuexSharedMutations,
    ],
  })(store);

  if (!isProduction) {
    persistCurrency.plugin(store);
  }
};
