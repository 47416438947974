import Vue from 'vue';
// import VueLottiePlayer from 'vue-lottie-player';
import VueLazyLoad from 'vue-lazyload';
import VTooltip from 'v-tooltip';
import Vuelidate from 'vuelidate';
import VueClipboard from 'vue-clipboard2';
import VueHtmlToPaper from 'vue-html-to-paper';

const vueHtmlToPaperOptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
  ],
  timeout: 1000, // default timeout before the print window appears
};


VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.use(VueLazyLoad);
Vue.use(VTooltip);
Vue.use(Vuelidate);
Vue.use(VueHtmlToPaper, vueHtmlToPaperOptions);
// Vue.use(VueLottiePlayer);
