// for caching experiment variantId
const state = {
  experiments: [],
};

const getters = {
  // getExperimentVariantId: (state) => (experimentId) => state.experiments.find(x => x.id === experimentId) || null,
  getExperiment: (state) => (experimentId) => state.experiments.find(x => x.id === experimentId) || null,
};

const actions = {
};

const mutations = {

  setExperimentVariant: (state, data) => {
    // state.experimentVariant = id;
    const experiment = state.experiments.find(x => x.id === data.id);
    if (!experiment) {
      state.experiments.push({
        id: data.id,
        variantId: data.variantId,
      });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
