export default [
  // navbar
  'app/setNavbarRecentlyVisited',
  // cart
  'cart/addCartItem',
  'cart/setNavCartItems',
  'cart/setTotalCount',
  'cart/clearCart',
  'cart/removeItem',
  // favorites
  'favorites/setDefaultFolderTotalCount',
  'favorites/updateAddedToday',
  'favorites/findAndRemoveFavoriteItemsFromAddedToday',
];
