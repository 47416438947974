//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { faAngleDown, faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons';
import { listFaqs } from '@motionelements/core/src/api/faq.js';
import getLangFromUrlMixin from '@motionelements/core/src/components/mixins/get-lang-from-url.mixin.js';

export default {
  name: 'HomeFaqs',
  mixins: [getLangFromUrlMixin],
  props: {
    faqsType: {
      type: String,
      required: true,
    },
    helpCenterLink: {
      type: String,
      default: 'https://help.motionelements.com/hc',
    },
  },
  data() {
    return {
      faAngleDown,
      faExternalLinkAlt,
      faqs: [],
      isFaqsLoading: false,
    };
  },
  head() {
    return {
      script: this.pageJsonLds,
    };
  },
  async fetch() {
    return this.listFaqs();
  },
  mounted() {
    if (!this.faqs.length && !this.isFaqsLoading) {
      this.listFaqs();
    }
  },
  computed: {
    faqJsonLd() {
      if (process.server
          && Array.isArray(this.faqs) && this.faqs.length) {
        const jsonLd = {
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          '@id': 'HP',
          'mainEntity': this.faqs.map(faq => ({
            '@type': 'Question',
            'name': _.get(faq, 'question'),
            'acceptedAnswer': {
              '@type': 'Answer',
              'text': _.get(faq, 'answer.html'),
            },
          })),
        };

        return {
          type: 'application/ld+json',
          json: jsonLd,
        };
      }
      return null;
    },
    pageJsonLds() {
      const scripts = [];

      if (this.faqJsonLd) {
        scripts.push(this.faqJsonLd);
      }

      return scripts;
    },
  },
  methods: {
    async listFaqs() {
      this.isFaqsLoading = true;
      return listFaqs(this.faqsType, this)
        .then((response) => {
          this.faqs = response.data.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isFaqsLoading = false;
        });
    },
  },
};
