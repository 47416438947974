
const state = {
  isIE: null,
  isMobile: null,
  window: {
    width: 0,
  },
};

const getters = {
};

const actions = {
};

const mutations = {
  setIE: (state, bool) => {
    state.isIE = bool;
  },
  setMobile: (state, bool) => {
    state.isMobile = bool;
  },
  setWindowWidth(state, width) {
    state.window.width = width;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
