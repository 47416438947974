//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import { bootstrapApp } from '@motionelements/core/src/services/app.service';
import windowResizeMixin from '@motionelements/core/src/components/mixins/window-resize.mixin.js';
import layoutsHeadMixin from '@/components/mixins/layouts-head.mixin.js';

export default {
  name: 'BaseLayout',
  mixins: [layoutsHeadMixin, windowResizeMixin],
  components: {
    Footer: () => import('@motionelements/core/src/components/TheFooter.vue'),
    Blocker: () => import('@motionelements/core/src/components/ui/Blocker.vue'),
    ShinySvgGradient: () => import('@motionelements/core/src/components/product/ShinySvgGradient.vue'),
    FlashNotifications: () => import('@/components/core/notifications/BaseFlashNotifications.vue'),
    ToastNotifications: () => import('@/components/core/notifications/BaseToastNotifications.vue'),
    SignUpModalWrapper: () => import('./ModalWrapper.vue'),
  },
  beforeCreate() {
    if (!process.server) {
      bootstrapApp(this);
    }
  },
  computed: {
    ...mapState({
      showBlocker: state => state.blocker.show,
      blockerMessage: state => state.blocker.message,
    }),
    pageStyle() {
      if (process.client && process.env.VUE_APP_ENV !== 'production') {
        if (this.$route.query.screenshot_lokalise) {
          return 'white-space: nowrap !important; font-family: Arial, Helvetica, sans-serif !important;';
        }
      }
      return '';
    },
  },
};
