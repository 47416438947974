import featureFlagMixin from '@motionelements/core/src/components/mixins/feature-flag.mixin.js';

export const channelCode = {
  VIDEO: 'video',
  MUSIC: 'music',
  SFX: 'sfx',
  IMAGE: 'image',
  '3D': '3d',
  LOTTIE: 'lottie',
  AE: 'ae',
  PR: 'pr',
  FCP: 'fcp',
  RESOLVE: 'resolve',
  MIDJORNEY: 'midjourney_prompt',
  IMAGE_PROMPT: 'image_prompt',
  GRAPHIC: 'graphic',
  UI: 'ui',
  NOTION: 'notion',
  PRESENTATION: 'presentation',
  GIF: 'gif',
};

let channelPrompt = {
  id: channelCode.MIDJORNEY,
  link: '/midjourney-prompts',
  // freeLink: '/free/midjourney-prompts',
  isNew: true,
  isFeatureEnabled: 'buyer_midjourney_prompt',
};

if (featureFlagMixin.methods.isFeatureEnabled('image_prompt_channel')) {
  channelPrompt = {
    id: channelCode.IMAGE_PROMPT,
    link: '/image-prompts',
    // freeLink: '/free/image-prompts',
    isNew: true,
    isFeatureEnabled: 'buyer_midjourney_prompt',
  };
}

export const channelTypes = [
  {
    id: channelCode.VIDEO,
    link: '/video',
    freeLink: '/free/stock-footage',
  },
  {
    id: channelCode.MUSIC,
    link: '/music',
    freeLink: '/free/background-music',
  },
  {
    id: channelCode.SFX,
    link: '/sound-effects',
    freeLink: '/free/sound-effects',
  },
  {
    id: channelCode.IMAGE,
    link: '/stock-image',
    freeLink: '/free/stock-image',
  },
  {
    id: channelCode['3D'],
    link: '/3d',
  },
  {
    id: channelCode.LOTTIE,
    link: '/lottie',
  },
  {
    id: channelCode.GRAPHIC,
    link: '/graphic',
    freeLink: '/free/graphic',
    isNew: true,
    isFeatureEnabled: 'buyer_new_media_types',
  },
  channelPrompt,
  {
    id: channelCode.AE,
    link: '/after-effects-templates',
    freeLink: '/free/after-effects-templates',
  },
  {
    id: channelCode.PR,
    link: '/premiere-pro-templates',
    freeLink: '/free/premiere-pro-templates',
  },
  {
    id: channelCode.FCP,
    link: '/apple-motion-templates',
    freeLink: '/free/fcpx-motion-templates',
  },
  {
    id: channelCode.RESOLVE,
    link: '/davinci-resolve-templates',
    freeLink: '/free/davinci-resolve-templates',
  },
  {
    id: channelCode.UI,
    link: '/ui',
    // freeLink: '/free/ui',
    isNew: true,
    isFeatureEnabled: 'buyer_new_media_types',
  },
  {
    id: channelCode.NOTION,
    link: '/notion',
    // freeLink: '/free/notion',
    isNew: true,
    isFeatureEnabled: 'buyer_new_media_types',
  },
  {
    id: channelCode.PRESENTATION,
    link: '/presentation',
    freeLink: '/free/presentation',
    isNew: true,
    isFeatureEnabled: 'buyer_new_media_types',
  },
];

const getChannelTypeById = (mediaTypeId) => channelTypes.find(({ id }) => id === mediaTypeId);

const freeChannelTypesIds = () => {
  const ids = [
    channelCode.VIDEO,
    channelCode.MUSIC,
    channelCode.SFX,
    channelCode.IMAGE,
    channelCode.AE,
    channelCode.PR,
    channelCode.FCP,
    channelCode.GIF,
    channelCode.RESOLVE,
    // channelCode.MIDJORNEY,
    // channelCode.IMAGE_PROMPT,
    channelCode.GRAPHIC,
    // channelCode.UI,
    // channelCode.NOTION,
    channelCode.PRESENTATION,
  ];
  return ids;
};

export const freeMediaTypes = freeChannelTypesIds().map(id => {
  if (id === channelCode.GIF) {
    return {
      id: channelCode.GIF,
      freeLink: '/free/gifs',
    };
  }

  return getChannelTypeById(id);
});

export const getChannelCode = (mediaType) => {
  switch (mediaType) {
    case 'video':
    case 'video_live':
    case 'animation':
    case 'video_vr':
      return channelCode.VIDEO;
    case 'ae':
    case 'ae_preset':
    case 'ae_template':
      return channelCode.AE;
    case 'motion':
    case 'motion_template':
      return channelCode.FCP;
    case 'pr':
    case 'pr_template':
    case 'pr_preset':
    case 'mg_template':
      return channelCode.PR;
    case 'sfx':
      return channelCode.SFX;
    case 'image':
    case 'photo':
    case 'photo_vr':
    case 'vector':
    case 'gif':
      return channelCode.IMAGE;
    case '3d_model':
    case '3d':
      return channelCode['3D'];
    case 'music':
      return channelCode.MUSIC;
    case 'lottie':
      return channelCode.LOTTIE;
    case 'resolve_macro':
    case 'resolve_template':
    case 'resolve':
      return channelCode.RESOLVE;
    case 'midjourney_prompt':
      return channelCode.MIDJORNEY;
    case 'graphic_template':
      return channelCode.GRAPHIC;
    case 'ui_template':
      return channelCode.UI;
    case 'notion_template':
      return channelCode.NOTION;
    case 'presentation_template':
      return channelCode.GRAPHIC;
    default:
      return mediaType;
  }
};
