import {
  STORAGE_VUEX_CURRENCY_KEY,
  STORAGE_VUEX_CURRENCY_VERSION,
} from '@motionelements/core/src/app.constants';
import VuexPersistence from 'vuex-persist';

export default new VuexPersistence({
  key: STORAGE_VUEX_CURRENCY_KEY,
  storage: window.sessionStorage,
  reducer: ({ user }) => ({
    hash: `${user.jwt.id}|${user.language}|${user.currencyCode}|${STORAGE_VUEX_CURRENCY_VERSION}`,
    locale: user.language,
    app: { currencies: user.currencies },
  }),
  filter: (mutation) => [
    'user/setCurrencies',
  ].includes(mutation.type),
});
