import * as cartApi from '@motionelements/core/src/api/cart';
import Money from '@motionelements/money';
import notificationService from '@motionelements/core/src/services/notification.service.js';

function handleResponseMessages(response) {
  const message = _.get(response.data, 'messages[0]');
  const title = _.get(response.data, 'messages[0].title');
  if (message && title) {
    notificationService.alert({
      level: _.get(message, 'level', 'info'),
      title,
    });
  }
}

const checkoutCartElementFields = 'html.url,name,price,thumbnails,sku,object,id,mediaType,mediaTypeDetails,variants,downloadable,credits,current,status';
const navCartElementFields = 'html.url,name,price,thumbnails,sku,status';

const state = {
  items: [],
  navItems: [],
  navItemsLimit: 3,
  totalItemsCount: 0,
  totalCredits: 0,
  totalPrice: null,
  vat: null,
  loading: false,
  navItemsLoading: false,
  pagination: {
    currentPage: 1,
    totalPage: 0,
    totalCount: 0,
    itemPerPage: 10,
  },
};

const getters = {
  getItems: state => state.items,
  getNavItems: state => state.navItems,
  getTotalItemsCount: state => state.totalItemsCount,
};

const actions = {
  getSummary({ commit }) {
    return cartApi.getCartSummary().then((response) => {
      const data = response.data.data;

      if (_.has(data, 'total.amount')) {
        const totalPrice = Money({ amount: data.total.amount, currency: data.total.currency });
        commit('setTotalPrice', totalPrice);
      }

      if (_.has(data, 'credits')) {
        commit('setTotalCredits', data.credits);
      }

      if (_.has(data, 'vat')) {
        commit('setVat', data.vat);
      }
    });
  },
  listItems({ commit, state }) {
    commit('setLoading', true);
    return cartApi.listItems({
      page: state.pagination.currentPage,
      per_page: state.pagination.itemPerPage,
      'fields[element]': checkoutCartElementFields,
    }).then((response) => {
      commit('setCartItems', response.data.data);
      commit('setTotalCount', response.data.totalCount);
      commit('setPagination', response.data);
      return response;
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      commit('setLoading', false);
    });
  },
  onChangeCartPage({ commit, dispatch }, page) {
    commit('setPaginationPage', page);
    dispatch('listItems');
  },
  listNavItems({ commit, state }) {
    commit('setNavItemsLoading', true);
    return cartApi.listItems({
      per_page: state.navItemsLimit,
      'fields[element]': navCartElementFields,
    }).then((response) => {
      commit('setNavCartItems', response.data.data);
      commit('setTotalCount', response.data.totalCount);
      return response;
    }).catch((e) => {
      console.error(e);
    }).finally(() => {
      commit('setNavItemsLoading', false);
    });
  },
  ADD_ELEMENTS_TO_CART({ state, commit }, payload) {
    const params = {
      'fields[element]': checkoutCartElementFields,
    };
    return cartApi.addItems(payload, params)
      .then(response => {
        if (_.get(response, 'data.messages', []).length) {
          handleResponseMessages(response);
        }
        const data = response.data.data;
        if (data.length) {
          commit('addCartItem', data);
          commit('setNavCartItems', _.uniqBy([...data, ...state.navItems], 'id'));
          commit('setTotalCount', state.totalItemsCount + 1);
        }
        return response;
      });
  },
  removeItem({ commit, dispatch, state }, sku) {
    return cartApi.removeItem(sku)
      .then((response) => {
        // if not error, it's removed
        commit('setTotalCount', state.totalItemsCount - 1);
        // update nav cart if removed one of them
        const indexNav = state.navItems.findIndex(item => item.sku === sku);
        if (indexNav > -1) {
          const cartItems = state.items;
          cartItems.slice(0, state.navItemsLimit);
          commit('setNavCartItems', cartItems);
        }
        commit('removeItem', sku);

        // set pagination.page to 1 if no items on current page
        if (state.items.length === 0) {
          commit('setPaginationPage', 1);
        }
        // reload items if not last page or no items
        const notLastPage = state.pagination.totalCount > state.pagination.itemPerPage * state.pagination.currentPage;
        if (notLastPage || state.items.length === 0) {
          dispatch('listItems');
        }
        // dispatch('getSummary');
        return response;
      });
  },
  updateProductVariant({ state, commit }, { currentSku, newSku, productId }) {
    const index = state.items.findIndex(x => x.id === productId);
    const indexNav = state.navItems.findIndex(x => x.id === productId);
    const payload = {
      sku: newSku,
    };
    const params = {
      'fields[element]': checkoutCartElementFields,
    };
    return cartApi.updateItem(currentSku, payload, params).then((response) => {
      const cartItems = state.items;
      cartItems.splice(index, 1, response.data.data);
      commit('setCartItems', cartItems);
      if (indexNav > -1) {
        const cartNavItems = state.navItems;
        cartNavItems.splice(index, 1, response.data.data);
        commit('setNavCartItems', cartNavItems);
      }
    });
  },
  checkout({ commit }, payload) {
    const params = {
      method: payload.method,
    };
    return cartApi.checkout(params).then((response) => {
      if (response.status === 201) {
        commit('clearCart');
      }
      return response;
    });
  },
  CLEAR_CART({ commit }) {
    return cartApi.clearCart()
      .then(() => {
        commit('clearCart');
      });
  },
};

const mutations = {
  // setCart(state, result) {
  //   state.count = _.size(result.data);
  //   state.items = result.data;
  // },
  clearCart(state) {
    state.items = [];
    state.navItems = [];
    state.totalCredits = 0;
    state.totalPrice = null;
    state.totalItemsCount = 0;
  },
  // setItems(state, payload) {
  //   // Object.keys(cartItems).map((key) => {
  //   //   const item = cartItems[key];
  //   //   const price = Money({ amount: item.price, currency: item.currency });
  //   //   // cartItems[key].priceString = `${symbol} ${price}`;
  //   //   cartItems[key].priceString = price.toFormat('$0,0');
  //   // });
  //   // state.count = _.size(cartItems);
  //   state.items = payload.data;
  // },
  setCartItems(state, cartItems) {
    state.items = cartItems;
  },
  addCartItem(state, cartItem) {
    if (state.pagination.currentPage === 1) {
      state.items = _.uniqBy([...cartItem, ...state.items], 'id');
    }
  },
  setTotalCount(state, length) {
    state.totalItemsCount = length;
  },
  removeItem(state, sku) {
    const index = state.items.findIndex(item => item.sku === sku);
    if (index > -1) state.items.splice(index, 1);
  },
  setTotalPrice(state, money) {
    state.totalPrice = money;
  },
  setTotalCredits(state, credits) {
    state.totalCredits = credits;
  },
  setVat(state, vat) {
    state.vat = vat;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  setNavCartItems(state, cartItems) {
    state.navItems = cartItems;
  },
  setNavItemsLoading(state, loading) {
    state.navItemsLoading = loading;
  },
  setPaginationPage(state, page) {
    state.pagination.currentPage = page;
  },
  setPagination(state, data) {
    state.pagination.currentPage = data.page;
    state.pagination.itemPerPage = data.perPage;
    state.pagination.totalCount = data.totalCount;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
