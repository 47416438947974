import { channelCode } from '@motionelements/core/src/helpers/channelTypes.js';

const state = () => ({
  channelType: channelCode.VIDEO,
});

const getters = {};

const actions = {
  setChannelType({ commit }, channelType) {
    commit('setChannelType', channelType);
  },
};

const mutations = {
  setChannelType(state, data) {
    state.channelType = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
