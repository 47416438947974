//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseLayout from '@/components/core/BaseLayout.vue';
import NavBar from '@/components/core/TheNavBar.vue';
import Sidebar from '@/components/legal/LegalSidebar.vue';

export default {
  name: 'LegalPageLayout',
  components: {
    Sidebar,
    BaseLayout,
    NavBar,
  },
  computed: {
    printFriendly() {
      return _.get(this.$route.query, 'print', '') === 'friendly';
    },
  },
};
