import Vue from 'vue';

import {
  ModalPlugin,
  TablePlugin,
  DropdownPlugin,
  CollapsePlugin,
  TabsPlugin,
  SidebarPlugin,
  AlertPlugin,
  ToastPlugin,
  VBTooltipPlugin,
  VBScrollspyPlugin
} from 'bootstrap-vue';

Vue.use(ModalPlugin);
Vue.use(TablePlugin);
Vue.use(DropdownPlugin);
Vue.use(CollapsePlugin);
Vue.use(TabsPlugin);
Vue.use(SidebarPlugin);
Vue.use(AlertPlugin);
Vue.use(ToastPlugin);

Vue.use(VBTooltipPlugin);
Vue.use(VBScrollspyPlugin);
