import VuexPersistence from 'vuex-persist';
import { STORAGE_VUEX_CART_KEY, STORAGE_VUEX_CART_VERSION } from '@motionelements/core/src/app.constants.js';

export default new VuexPersistence({
  key: STORAGE_VUEX_CART_KEY,
  storage: window.sessionStorage,
  reducer: ({ user, cart }) => ({
    hash: `${user.jwt.id}|${user.language}|${user.currencyCode}|${STORAGE_VUEX_CART_VERSION}`,
    cart: {
      navItems: cart.navItems,
      totalItemsCount: cart.totalItemsCount,
    },
  }),
  // eslint-disable-next-line
  filter: (mutation) => {
    return [
      'cart/addCartItem',
      'cart/setNavCartItems',
      'cart/setTotalCount',
      'cart/clearCart',
      'cart/removeItem',
      // 'cart/setTotalPrice',
      // 'cart/setTotalCredits',
      // 'cart/setVat',
    ].includes(mutation.type);
  },
});
