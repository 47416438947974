import { listSubscriptionPlans } from '@/api/subscription-plans.js';
import Money from '@motionelements/money';

const state = () => ({
  plans: null,
});

const getters = {
  isPlansLoaded: state => Array.isArray(state.plans),
  getPlanById: state => (id) => {
    if (state.plans === null) {
      return null;
    }

    if (!id) {
      return null;
    }

    id = id.replace('-', '_').toLowerCase();

    return state.plans.find(plan => plan.id === id);
  },
  getPlanBilledYearly: state => {
    if (state.plans === null) {
      return null;
    }

    return state.plans.find(plan => _.get(plan, 'billing.interval') === 'year');
  },
  getPlanBilledMonthly: state => {
    if (state.plans === null) {
      return null;
    }

    return state.plans.find(plan => _.get(plan, 'billing.interval') === 'month');
  },
  getPlanPriceMonthlyString: (state, getters) => {
    const priceMonthly = _.get(getters.getPlanBilledYearly, 'priceMonthly', null);

    return priceMonthly ? Money(priceMonthly).toFormat('$0,0') : '';
  },
};

const actions = {
  async getPlans({ commit }) {
    return listSubscriptionPlans().then((response) => {
      commit('setPlans', response.data.data);
      return response;
    });
  },
};

const mutations = {
  setPlans(state, data) {
    state.plans = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
