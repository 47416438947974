import api from '@motionelements/core/src/api/base-api-v2.js';
import getLangFromUrlMixin from '@motionelements/core/src/components/mixins/get-lang-from-url.mixin.js';

// eslint-disable-next-line
export const listFaqs = async (type, nuxt) => {
  const language = nuxt && getLangFromUrlMixin.methods.getLanguageFromUrl.call(nuxt);
  const params = {
    type,
    ...api.getLocaleParams(),
    ...(language && { language }),
  };
  console.log(params);
  return api.get('/v2/faqs', {
    withCredentials: false,
    params,
  });
};
