function convert(id) {
  switch (id) {
    case 'terms':
      return 'tou';
    case 'license':
      return 'rf_license_single_seat';
    case 'rf-license-multi-seat':
      return 'rf_license_multi_seat';
    case 'copyright':
      return 'dmca';
    default:
      return id.replace(/-/g, '_');
  }
}

function revert(id) {
  switch (id) {
    case 'tou':
      return 'terms';
    case 'rf_license_single_seat':
      return 'license';
    case 'rf_license_multi_seat':
      return 'rf-license-multi-seat';
    case 'dmca':
      return 'copyright';
    default:
      return id.replace(/_/g, '-');
  }
}

export default {
  convert,
  revert,
};
