
export default {
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
    this.updateWindowSize();
  },
  methods: {
    onWindowResize: _.debounce(function () {
      this.updateWindowSize();
    }, 300), // delay 300ms between resize events
    updateWindowSize() {
      // console.log('updateWindowWidth', window.innerWidth);
      // set current windows width for search page layout
      this.$store.commit('browser/setWindowWidth', window.innerWidth);
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },
};
