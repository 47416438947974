import cookies from 'js-cookie';

import {
  COOKIE_JWT_KEY,
} from '@motionelements/core/src/app.constants.js';

export function getJwt() {
  return cookies.get(COOKIE_JWT_KEY);
}

// not signature for Vue
function decodeJwt(token) {
  if (!token) {
    return null;
  }
  // const parts = token.split('.');
  // const header = JSON.parse(atob(parts[0]));
  // const payload = JSON.parse(atob(parts[1]));
  // const signature = atob(parts[2].replace(/_/g, '/').replace(/-/g, '+'));

  // return {
  //   header: header,
  //   payload: payload,
  //   // signature: signature,
  //   // raw: { header: parts[0], payload: parts[1], signature: parts[2] },
  // };
  const [header, payload] = token.split('.').slice(0, 2)
    .map(el => el.replace(/-/g, '+').replace(/_/g, '/'))
    .map(el => JSON.parse(window.atob(el)));
  return { header, payload };
}

// not signature for Vue
export function getJwtPayload() {
  try {
    const result = decodeJwt(getJwt());
    return result ? result.payload : {};
  } catch (error) {
    console.error(error);
    return {};
  }
}
