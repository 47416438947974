export const publishedContentCounts = {
  total: {
    element: 15000000,

    // by media type
    '3d': 1000,
    ae: 40000,
    animation: 800000,
    fcp: 5000,
    gif: 2500000,
    music: 700000,
    photo: 10000000,
    pr: 22000,
    resolve: 3000,
    sfx: 300000,
    vector: 1000000,
    video_live: 3500000,

    // group
    image: 10000000,
    video: 4000000,

    free: {
      element: 20000,
      // by media type
      ae: 300,
      fcp: 40,
      gif: 6000,
      image: 10000,
      music: 3000,
      resolve: 10,
      sfx: 1000,
      video: 8000,
    },

    subscription: {
      element: 14000000,
      // by media type
      '3d': 800,
      ae: 35000,
      fcp: 6000,
      image: 10000000,
      music: 500000,
      pr: 20000,
      resolve: 3000,
      sfx: 280000,
      vector: 900000,
      video: 3600000,
    },
  },
};

export const renderContentCount = (vue, count, precision, useShort) => {
  switch (vue.$i18n.locale) {
    case 'ja':
    case 'zh-hans':
      if (count >= 100000) {
        return `${Math.floor(count / 100000) * 10}万`; // round off last digit to 0
      }
      if (count >= 10000) {
        return `${Math.floor(count / 10000)}万`;
      }
      return count;

    case 'zh-hant':
      if (count >= 100000) {
        return `${Math.floor(count / 100000) * 10}萬`; // round off last digit to 0
      }
      if (count >= 10000) {
        return `${Math.floor(count / 10000)}萬`;
      }
      return count;

    case 'ko':
      if (count >= 100000) {
        return `${Math.floor(count / 100000) * 10}만`; // round off last digit to 0
      }
      if (count >= 10000) {
        return `${Math.floor(count / 10000)}만`;
      }
      return count;

    default:
      if (count >= 1000000) {
        const decimals = precision > 0 ? precision * 10 : 1;
        if (useShort) {
          return `${Math.trunc((count / 1000000) * decimals) / decimals}m`;
        }
        // if (precision > 0) {
        //   const decimals = precision * 10;
          return vue.$t('phrase.{number}_million', { number: Math.trunc((count / 1000000) * decimals) / decimals });
        // }
        // return vue.$t('phrase.{number}_million', { number: Math.floor(count / 1000000) });
      }

      if (count >= 1000 && useShort) {
        const decimals = precision > 0 ? precision * 10 : 1;
        return `${Math.trunc((count / 1000) * decimals) / decimals}k`;
      }

      // eslint-disable-next-line no-case-declarations
      const countFormat = new Intl.NumberFormat('en-US', {});

      return countFormat.format(count);
  }
};
