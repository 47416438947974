import api from '@motionelements/core/src/api/base-api-v2.js';

const baseUrl = '/v2/agreements';

export const listAgreements = () => api.get(baseUrl, {
  params: {
    ...api.getLocaleParams(),
  },
});

export const getAgreement = id => api.get(`${baseUrl}/${id.replace(/_/g, '-')}`, {
  params: {
    ...api.getLocaleParams(),
  },
});

export const acceptAgreement = id => api.request('post', `${baseUrl}/${id.replace(/_/g, '-')}/accept`);
