//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

import * as documentsApi from '@motionelements/core/src/api/agreements.js';

import mapId from '@/components/legal/mapId.js';

export default {
  name: 'LegalSidebar',
  data() {
    return {
      list: [],
    };
  },
  async fetch() {
    return this.listAgreements();
  },
  watch: {
    isMember: {
      handler(value) {
        if (value) {
          this.listAgreements();
        } else if (!this.list.length) {
          this.listAgreements();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      isMember: 'user/isMember',
    }),
    partOfList() {
      return listArray => this.list.filter(item => listArray.includes(item.id));
    },
    sidebarGroups() {
      return [
        {
          heading: this.$t('nav.for_creators'),
          items: ['tou', 'rf_license_single_seat', 'rf_license_multi_seat', 'privacy'],
        },
        {
          heading: this.$t('nav.for_contributors'),
          items: ['contributor', 'gcp_subscription', 'gcp_api_edit', 'gcp_apac_marketplace_partners'],
        },
        {
          heading: this.$t('term.others'),
          items: ['dmca', 'ecommerce'],
        },
      ];
    },
  },
  methods: {
    getDocumentPath(id) {
      return this.$link(`/legal/${mapId.revert(id)}`).fullPath();
    },
    async listAgreements() {
      return documentsApi.listAgreements()
        .then(({ data: { data } }) => {
          this.list = data;
        })
        .catch(err => {
          console.error(err);
        });
    },
  },
};
