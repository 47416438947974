import api from './base-api-v2';

// export const getAccount = async () => api.get('/v2/account?include=favorites');

export const getAccount = async (params) => {
  params = params || {};
  // params.include = 'favorites';
  const request = api.get('/v2/account', {
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
  });
  return request;
};

export const updateAccount = async (params) => {
  const request = api.request('patch', '/v2/account', {
    params: api.getLocaleParams(),
    data: params,
  });
  return request;
};

export const accountVerify = async (params) => {
  const request = api.request('post', '/v2/account/verify', {
    data: params,
  });
  return request;
};

export const refreshJwt = async () => {
  const request = api.request('post', '/v2/account/refresh-jwt', {
    // data: params,
  });
  return request;
};

export const getFreeDownloadsRemaining = async () => api.get('/v2/account/free-download');

export const signUp = async (payload, axiosConfig) => {
  const request = await api.request('post', '/v2/account', {
    ...(axiosConfig || {}), // for recaptcha header
    withCredentials: false,
    data: payload,
    params: api.getLocaleParams(),
  });
  return request;
};

export const logIn = async (payload, axiosConfig) => {
  const request = await api.request('post', '/v2/account/login', {
    ...(axiosConfig || {}), // for recaptcha header
    withCredentials: false,
    data: payload,
  });
  return request;
};

export const updatePassword = async (payload, axiosConfig) => {
  const request = await api.request('post', '/v2/account/password', {
    ...(axiosConfig || {}), // for recaptcha header
    data: payload,
    params: api.getLocaleParams(),
  });
  return request;
};

export const resendVerifyEmail = async () => {
  const request = await api.request('post', '/v2/account/send-activation-email', {
    withCredentials: true,
    // data: payload,
  });
  return request;
};

// eslint-disable-next-line
export const validateUsername = (username, axiosConfig) => {
  return api.get('/v2/account/validate-username', {
    params: {
      username,
    },
    ...(axiosConfig || {}),
  });
};

// PROFILE

export const getAccountProfileCompletion = async () => {
  const request = await api.request('get', '/v2/account/profile', {
  });
  return request;
};

export const getAccountProfileDetails = async () => {
  const request = await api.request('get', '/v2/account?fields[member]=billing_details,artist_details,address,phone,tools', {
  });
  return request;
};

export const getMemberNotifications = async () => {
  const request = await api.request('get', '/v2/notifications', {
  });
  return request;
};

export const getPayoneerPayee = async () => {
  const request = await api.request('get', '/v2/account/payoneer', {
  });
  return request;
};

// export const getPayoneerJobStatus = async (id) => {
//   const request = await api.request('get', `/v2/jobs/${id}`, {
//   });
//   return request;
// };

export const createPayoneerPayee = async (payload) => {
  const request = await api.request('post', '/v2/account/payoneer', {
    data: payload,
  });
  return request;
};

export const listSubscriptionPlans = async () => {
  const request = await api.request('get', '/v2/subscription-plans', {
    params: {
      ...api.getLocaleParams(),
    },
  });
  return request;
};

export const getSubscriptionPlan = async (payload) => {
  const request = await api.request('get', '/v2/subscription-plans', {
    params: {
      ...api.getLocaleParams(),
    },
    data: payload,
  });
  return request;
};

// export const updateProfileImage = async ({ type, blob }) => {
//   const url = '/v2/artist/pages/image';
//   return api.request('put', url, {
//     data: {
//       type: type,
//       blob: blob,
//     },
//   });
// };

export const updateAvatar = async ({ blob }) => {
  const request = await api.request('put', '/v2/account/avatar', {
    data: {
      blob: blob,
    },
  });
  return request;
};

export const sendResetPasswordEmail = async (payload, axiosConfig) => {
  const request = await api.request('post', '/v2/account/reset-password', {
    ...(axiosConfig || {}), // for recaptcha header
    data: payload,
    withCredentials: false,
    params: api.getLocaleParams(),
  });
  return request;
};

export const resetPassword = async (payload, axiosConfig) => {
  const request = await api.request('post', '/v2/account/confirm-reset-password', {
    ...(axiosConfig || {}), // for recaptcha header
    data: payload,
    withCredentials: false,
    params: api.getLocaleParams(),
  });
  return request;
};

export const changeEmail = async (payload, axiosConfig) => {
  const request = await api.request('post', '/v2/account/change-email', {
    ...(axiosConfig || {}), // for recaptcha header
    data: payload,
    params: api.getLocaleParams(),
  });
  return request;
};

// export const verifyChangeEmail = async (payload) => {
//   const request = await api.request('post', '/v2/account/verify-change-email', {
//     data: payload,
//     params: api.getLocaleParams(),
//   });
//   return request;
// };

// eslint-disable-next-line
export const validatePortfolioUrl = (url, axiosConfig) => {
  return api.get('/v2/account/validate-portfolio-url', {
    params: {
      url,
    },
    ...(axiosConfig || {}),
  });
};

export const getCoinsBalance = async () => api.get('/v2/coins/balance');
