import {
  faShapes,
  faPhotoFilmMusic,
  faFileCircleCheck,
  faCube,
  faSliders,
  faFilePen,
  faBolt,
  faClockRotateLeft,
  faGrid2Plus,
  faPenToSquare,
} from '@fortawesome/pro-light-svg-icons';
import { publishedContentCounts, renderContentCount } from '@motionelements/core/src/helpers/contentCount.js';
import { channelCode } from '@motionelements/core/src/helpers/channelTypes.js';
import {
  faMeAeUniverseLight,
  faMeEasyLight,
  faMeSearchAiLight,
  faMeYoutubeLight,
} from '@/assets/icons/me-font-icons-regular';

const benefits = (vue) => ({
  easyLicensing: {
    icon: faMeEasyLight,
    color: 'aqua',
    heading: vue.$t(vue.$wbr('channel.benefits.easy_licensing_heading')),
    text: vue.$t(vue.$wbr('channel.benefits.easy_licensing_text')),
  },
  customizableTemplates: {
    icon: faPenToSquare,
    color: 'lavender',
    heading: vue.$t(vue.$wbr('channel.benefits.customizable_templates_heading')),
    text: vue.$t(vue.$wbr('channel.benefits.customizable_templates_text')),
  },
});

// eslint-disable-next-line import/prefer-default-export
export const getChannel = (vue, channelId) => {
  const priceMonthly = vue.$store.getters['subscription/getPlanPriceMonthlyString'];

  const promptChannelHeading = vue.$term('mediaType', channelId).plural().text();

  const promptChannel = {
    heading: promptChannelHeading,
    headingHtml: vue.$term('mediaType', channelId).wbr().plural().text(),
    pageTitle: vue.$t('channel.image_prompt.page_title'),
    pageDescription: vue.$t('channel.image_prompt.page_description', {
      price: priceMonthly,
    }),
    subHeading: vue.$t('channel.midjourney.page_subheading_{media_type}', { media_type: promptChannelHeading }),
    subHeadingHtml: vue.$t(vue.$wbr('channel.midjourney.page_subheading_{media_type}'), { media_type: promptChannelHeading }),
    bg: 'linear-gradient(270.18deg, #360963 0.24%, #1B7D9C 99.92%)',
    benefitsHeading: vue.$t(vue.$wbr('channel.midjourney.benefits.heading')),
    benefits: [
      benefits(vue).easyLicensing,
      {
        icon: faClockRotateLeft,
        color: 'lavender',
        heading: vue.$t(vue.$wbr('channel.midjourney.benefits.time_saving_results_heading')),
        text: vue.$t(vue.$wbr('channel.midjourney.benefits.time_saving_results_text')),
      },
      {
        icon: faBolt,
        color: 'blue',
        heading: vue.$t(vue.$wbr('channel.midjourney.benefits.unlimited_subscription_heading')),
        text: vue.$t(vue.$wbr('channel.midjourney.benefits.unlimited_subscription_text_{media_type}'), { media_type: promptChannelHeading }),
      },
    ],
    leftBg: {
      top: '-136px',
      transformX: '182px',
    },
    rightBg: {
      top: '-130px',
      transformX: '215px',
    },
  };

  switch (channelId) {
    case channelCode.VIDEO:
      return {
        heading: null,
        headingHtml: null,
        pageTitle: vue.$t('channel.video.page_title'),
        pageDescription: vue.$t('channel.video.page_description', {
          count: renderContentCount(vue, publishedContentCounts.total.subscription.video),
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.video.page_subheading', { count: renderContentCount(vue, publishedContentCounts.total.video) }),
        subHeadingHtml: vue.$t(vue.$wbr('channel.video.page_subheading'), { count: renderContentCount(vue, publishedContentCounts.total.video) }),
        bg: 'linear-gradient(90deg, #8F3333 9.53%, #410873 100%)',
        benefitsHeading: vue.$t(vue.$wbr('channel.benefits.heading')),
        benefits: [
          benefits(vue).easyLicensing,
          {
            icon: faShapes,
            color: 'lavender',
            heading: vue.$t(vue.$wbr('channel.benefits.diverse_content_heading')),
            text: vue.$t(vue.$wbr('channel.video.benefits.diverse_content_text')),
          },
          {
            icon: faMeSearchAiLight,
            color: 'pink',
            heading: vue.$t(vue.$wbr('channel.benefits.visual_search_heading')),
            text: vue.$t(vue.$wbr('channel.video.benefits.visual_search_text')),
          },
        ],
        leftBg: {
          top: '-72px',
          transformX: '342px',
        },
        rightBg: {
          top: '130px',
          transformX: '369px',
        },
      };
    case channelCode.MUSIC:
      return {
        heading: null,
        headingHtml: null,
        pageTitle: vue.$t('channel.music.page_title'),
        pageDescription: vue.$t('channel.music.page_description', {
          count: renderContentCount(vue, publishedContentCounts.total.subscription.music),
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.music.page_subheading', { count: renderContentCount(vue, publishedContentCounts.total.music) }),
        subHeadingHtml: vue.$t(vue.$wbr('channel.music.page_subheading'), { count: renderContentCount(vue, publishedContentCounts.total.music) }),
        bg: 'linear-gradient(90deg, #1F1CBA 0%, #006278 100%)',
        leftBg: {
          top: '250px',
          transformX: '350px',
        },
        rightBg: {
          top: '-350px',
          transformX: '369px',
        },
        benefitsHeading: vue.$t(vue.$wbr('channel.benefits.heading')),
        benefits: [
          benefits(vue).easyLicensing,
          {
            icon: faMeYoutubeLight,
            color: 'lavender',
            heading: vue.$t(vue.$wbr('channel.music.benefits.safe_for_youtube_heading')),
            text: vue.$t(vue.$wbr('channel.music.benefits.safe_for_youtube_text')),
          },
          {
            icon: faMeSearchAiLight,
            color: 'pink',
            heading: vue.$t(vue.$wbr('channel.benefits.audio_search_heading')),
            text: vue.$t(vue.$wbr('channel.music.benefits.audio_search_text')),
          },
        ],
      };
    case channelCode.IMAGE:
      return {
        heading: vue.$t('channel.image.page_heading'),
        headingHtml: vue.$t(vue.$wbr('channel.image.page_heading')),
        pageTitle: vue.$t('channel.image.page_title'),
        pageDescription: vue.$t('channel.image.page_description', {
          count: renderContentCount(vue, publishedContentCounts.total.subscription.image),
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.image.page_subheading'),
        subHeadingHtml: vue.$t(vue.$wbr('channel.image.page_subheading')),
        bg: 'linear-gradient(90deg, #014685 8.75%, #311845 100%)',
        leftBg: {
          top: '-134px',
          transformX: '207px',
        },
        rightBg: {
          top: '91px',
          transformX: '184px',
        },
        benefitsHeading: vue.$t(vue.$wbr('channel.image.benefits.heading')),
        benefits: [
          benefits(vue).easyLicensing,
          {
            icon: faShapes,
            color: 'lavender',
            heading: vue.$t(vue.$wbr('channel.benefits.diverse_content_heading')),
            text: vue.$t(vue.$wbr('channel.image.benefits.diverse_content_text')),
          },
          {
            icon: faMeSearchAiLight,
            color: 'pink',
            heading: vue.$t(vue.$wbr('channel.benefits.visual_search_heading')),
            text: vue.$t(vue.$wbr('channel.image.benefits.visual_search_text')),
          },
        ],
      };
    case channelCode.SFX:
      return {
        heading: null,
        headingHtml: null,
        pageTitle: vue.$t('channel.sfx.page_title'),
        pageDescription: vue.$t('channel.sfx.page_description', {
          count: renderContentCount(vue, publishedContentCounts.total.subscription.sfx),
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.sfx.page_subheading', { count: renderContentCount(vue, publishedContentCounts.total.sfx) }),
        subHeadingHtml: vue.$t(vue.$wbr('channel.sfx.page_subheading'), { count: renderContentCount(vue, publishedContentCounts.total.sfx) }),
        bg: 'linear-gradient(90deg, #5A2489 0%, #13416C 100%)',
        leftBg: {
          top: '156px',
          transformX: '238px',
        },
        rightBg: {
          top: '-265px',
          transformX: '265px',
        },
        benefits: [],
      };
    case channelCode['3D']:
      return {
        heading: vue.$t('channel.3d.page_heading'),
        headingHtml: vue.$t(vue.$wbr('channel.3d.page_heading')),
        pageTitle: vue.$t('channel.3d.page_title'),
        pageDescription: vue.$t('channel.3d.page_description', {
          count: renderContentCount(vue, publishedContentCounts.total.subscription['3d']),
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.3d.page_subheading'),
        subHeadingHtml: vue.$t(vue.$wbr('channel.3d.page_subheading')),
        bg: 'linear-gradient(90deg, #764219 3.96%, #335E16 100%)',
        rightBg: {
          top: '88px',
          transformX: '202px',
        },
        benefitsHeading: vue.$t(vue.$wbr('channel.3d.benefits.heading')),
        benefits: [
          benefits(vue).easyLicensing,
          {
            icon: faFileCircleCheck,
            color: 'lavender',
            heading: vue.$t(vue.$wbr('channel.3d.benefits.compatible_heading')),
            text: vue.$t(vue.$wbr('channel.3d.benefits.compatible_text'), { formats: '.3ds, .blend, .c4d, .fbx, .max, .maya, .obj' }),
          },
          {
            icon: faCube,
            color: 'pink',
            heading: vue.$t(vue.$wbr('channel.3d.benefits.import_easily_heading')),
            text: vue.$t(vue.$wbr('channel.3d.benefits.import_easily_text')),
          },
        ],
      };
    case channelCode.LOTTIE:
      return {
        heading: null,
        headingHtml: null,
        pageTitle: vue.$t('channel.lottie.page_title'),
        pageDescription: vue.$t('channel.lottie.page_description', {
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.lottie.page_subheading'),
        subHeadingHtml: vue.$t(vue.$wbr('channel.lottie.page_subheading')),
        bg: 'linear-gradient(90deg, #097D65 9.53%, #275D9D 100%)',
        rightBg: {
          top: '19px',
          transformX: '-5px',
        },
        benefits: [],
      };
    case channelCode.PR:
      return {
        heading: null,
        headingHtml: null,
        pageTitle: vue.$t('channel.pr.page_title'),
        pageDescription: vue.$t('channel.pr.page_description', {
          count: renderContentCount(vue, publishedContentCounts.total.subscription.pr),
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.pr.page_subheading', { count: renderContentCount(vue, publishedContentCounts.total.pr) }),
        subHeadingHtml: vue.$t(vue.$wbr('channel.pr.page_subheading'), { count: renderContentCount(vue, publishedContentCounts.total.pr) }),
        bg: 'linear-gradient(90deg, #279D64 0%, #5C0000 100%)',
        leftBg: {
          top: '-612px',
          transformX: '-90px',
        },
        rightBg: {
          top: '106px',
          transformX: '168px',
        },
        benefitsHeading: vue.$t(vue.$wbr('channel.benefits.heading')),
        benefits: [
          benefits(vue).easyLicensing,
          {
            icon: faSliders,
            color: 'lavender',
            heading: vue.$t(vue.$wbr('channel.benefits.easy_to_customize_heading')),
            text: vue.$t(vue.$wbr('channel.benefits.easy_to_customize_text')),
          },
          {
            icon: faPhotoFilmMusic,
            color: 'pink',
            heading: vue.$t(vue.$wbr('channel.benefits.music_match_heading')),
            text: vue.$t(vue.$wbr('channel.benefits.music_match_text')),
          },
        ],
      };
    case channelCode.AE:
      return {
        heading: null,
        headingHtml: null,
        pageTitle: vue.$t('channel.ae.page_title'),
        pageDescription: vue.$t('channel.ae.page_description', {
          count: renderContentCount(vue, publishedContentCounts.total.subscription.ae),
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.ae.page_subheading', { count: renderContentCount(vue, publishedContentCounts.total.ae) }),
        subHeadingHtml: vue.$t(vue.$wbr('channel.ae.page_subheading'), { count: renderContentCount(vue, publishedContentCounts.total.ae) }),
        bg: 'linear-gradient(90deg, #27339D 0%, #0087A5 100%)',
        leftBg: {
          top: '152px',
          transformX: '180px',
        },
        rightBg: {
          top: '-830px',
          transformX: '-52px',
        },
        benefitsHeading: vue.$t(vue.$wbr('channel.benefits.heading')),
        benefits: [
          benefits(vue).easyLicensing,
          {
            icon: faMeAeUniverseLight,
            color: 'lavender',
            heading: vue.$t(vue.$wbr('channel.ae.benefits.compatible_heading')),
            text: vue.$t(vue.$wbr('channel.ae.benefits.compatible_text'), { formats: '.3ds, .blend, .c4d, .fbx, .max, .maya, .obj' }),
          },
          {
            icon: faPhotoFilmMusic,
            color: 'pink',
            heading: vue.$t(vue.$wbr('channel.benefits.music_match_heading')),
            text: vue.$t(vue.$wbr('channel.benefits.music_match_text')),
          },
        ],
      };
    case channelCode.RESOLVE:
      return {
        heading: null,
        headingHtml: null,
        pageTitle: vue.$t('channel.resolve.page_title'),
        pageDescription: vue.$t('channel.resolve.page_description', {
          count: renderContentCount(vue, publishedContentCounts.total.subscription.resolve),
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.resolve.page_subheading'),
        subHeadingHtml: vue.$t(vue.$wbr('channel.resolve.page_subheading')),
        bg: 'linear-gradient(90deg, #B8593B 0%, #7B341D 0.01%, #5A1095 100%)',
        benefitsHeading: vue.$t(vue.$wbr('channel.benefits.heading')),
        benefits: [
          benefits(vue).easyLicensing,
          {
            icon: faSliders,
            color: 'lavender',
            heading: vue.$t(vue.$wbr('channel.benefits.easy_to_customize_heading')),
            text: vue.$t(vue.$wbr('channel.benefits.easy_to_customize_text')),
          },
          {
            icon: faPhotoFilmMusic,
            color: 'pink',
            heading: vue.$t(vue.$wbr('channel.benefits.music_match_heading')),
            text: vue.$t(vue.$wbr('channel.benefits.music_match_text')),
          },
        ],
        rightBg: {
          top: '75px',
          transformX: '322px',
        },
      };
    case channelCode.FCP:
      return {
        heading: vue.$t('channel.fcp.page_heading'),
        headingHtml: vue.$t(vue.$wbr('channel.fcp.page_heading')),
        pageTitle: vue.$t('channel.fcp.page_title'),
        pageDescription: vue.$t('channel.fcp.page_description', {
          count: renderContentCount(vue, publishedContentCounts.total.subscription.fcp),
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.fcp.page_subheading'),
        subHeadingHtml: vue.$t(vue.$wbr('channel.fcp.page_subheading')),
        bg: 'linear-gradient(90deg, #0A58B4 0%, #8D6600 100%)',
        benefitsHeading: vue.$t(vue.$wbr('channel.benefits.heading')),
        benefits: [
          benefits(vue).easyLicensing,
          {
            icon: faFilePen,
            color: 'lavender',
            heading: vue.$t(vue.$wbr('channel.fcp.benefits.editable_in_fcp_heading')),
            text: vue.$t(vue.$wbr('channel.fcp.benefits.editable_in_fcp_text')),
          },
          {
            icon: faPhotoFilmMusic,
            color: 'pink',
            heading: vue.$t(vue.$wbr('channel.benefits.music_match_heading')),
            text: vue.$t(vue.$wbr('channel.benefits.music_match_text')),
          },
        ],
        rightBg: {
          top: '80px',
          transformX: '87px',
        },
      };
    case channelCode.MIDJORNEY:
    case channelCode.IMAGE_PROMPT:
      return promptChannel;
    case channelCode.GRAPHIC:
      return {
        heading: vue.$t('channel.graphic.page_heading'),
        headingHtml: vue.$t(vue.$wbr('channel.graphic.page_heading')),
        pageTitle: vue.$t('channel.graphic.page_title'),
        pageDescription: vue.$t('channel.graphic.page_description', {
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.graphic.page_subheading'),
        subHeadingHtml: vue.$t(vue.$wbr('channel.graphic.page_subheading')),
        bg: 'linear-gradient(90deg, #B89312 0%, #08789B 100%)',
        benefitsHeading: vue.$t(vue.$wbr('channel.graphic.benefits.heading')),
        benefits: [
          benefits(vue).easyLicensing,
          benefits(vue).customizableTemplates,
          {
            icon: faBolt,
            color: 'blue',
            heading: vue.$t(vue.$wbr('channel.graphic.benefits.compatibility_heading')),
            text: vue.$t(vue.$wbr('channel.graphic.benefits.compatibility_text')),
          },
        ],
        leftBg: {
          top: '76px',
          transformX: '323px',
        },
        rightBg: {
          top: '-284px',
          transformX: '181px',
        },
      };
    case channelCode.UI:
      return {
        heading: vue.$t('channel.ui.page_heading'),
        headingHtml: vue.$t(vue.$wbr('channel.ui.page_heading')),
        pageTitle: vue.$t('channel.ui.page_title'),
        pageDescription: vue.$t('channel.ui.page_description', {
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.ui.page_subheading'),
        subHeadingHtml: vue.$t(vue.$wbr('channel.ui.page_subheading')),
        bg: 'linear-gradient(270.18deg, #206309 0.24%, #1B209C 99.92%)',
        benefitsHeading: vue.$t(vue.$wbr('channel.ui.benefits.heading')),
        benefits: [
          benefits(vue).easyLicensing,
          benefits(vue).customizableTemplates,
          {
            icon: faGrid2Plus,
            color: 'pink',
            heading: vue.$t(vue.$wbr('channel.ui.benefits.compatibility_heading')),
            text: vue.$t(vue.$wbr('channel.ui.benefits.compatibility_text')),
          },
        ],
        leftBg: {
          top: '-383px',
          transformX: '227px',
        },
        rightBg: {
          top: '-85px',
          transformX: '141px',
        },
      };
    case channelCode.NOTION:
      return {
        heading: vue.$t('channel.notion.page_heading'),
        headingHtml: vue.$t(vue.$wbr('channel.notion.page_heading')),
        pageTitle: vue.$t('channel.notion.page_title'),
        pageDescription: vue.$t('channel.notion.page_description', {
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.notion.page_subheading'),
        subHeadingHtml: vue.$t(vue.$wbr('channel.notion.page_subheading')),
        bg: 'linear-gradient(90deg, #12A795 0%, #5717AA 100%)',
        benefitsHeading: vue.$t(vue.$wbr('channel.notion.benefits.heading')),
        benefits: [
          benefits(vue).easyLicensing,
          benefits(vue).customizableTemplates,
          {
            icon: faClockRotateLeft,
            color: 'pink',
            heading: vue.$t(vue.$wbr('channel.notion.benefits.productive_heading')),
            text: vue.$t(vue.$wbr('channel.notion.benefits.productive_text')),
          },
        ],
        leftBg: {
          top: '113px',
          transformX: '201px',
        },
        rightBg: {
          top: '-220px',
          transformX: '300px',
        },
      };
    case channelCode.PRESENTATION:
      return {
        heading: vue.$t('channel.presentation.page_heading'),
        headingHtml: vue.$t(vue.$wbr('channel.presentation.page_heading')),
        pageTitle: vue.$t('channel.presentation.page_title'),
        pageDescription: vue.$t('channel.presentation.page_description', {
          price: priceMonthly,
        }),
        subHeading: vue.$t('channel.presentation.page_subheading'),
        subHeadingHtml: vue.$t(vue.$wbr('channel.presentation.page_subheading')),
        bg: 'linear-gradient(269.05deg, #22857F 0.3%, #821010 98.7%)',
        benefitsHeading: vue.$t(vue.$wbr('channel.presentation.benefits.heading')),
        benefits: [
          benefits(vue).easyLicensing,
          {
            icon: faPenToSquare,
            color: 'lavender',
            heading: vue.$t(vue.$wbr('channel.benefits.customizable_templates_heading')),
            text: vue.$t(vue.$wbr('channel.presentation.benefits.customizable_templates_text')),
          },
          {
            icon: faGrid2Plus,
            color: 'pink',
            heading: vue.$t(vue.$wbr('channel.presentation.benefits.compatibility_heading')),
            text: vue.$t(vue.$wbr('channel.presentation.benefits.compatibility_text')),
          },
        ],
        leftBg: {
          top: '-76px',
          transformX: '115px',
        },
      };
    // no default
  }
};

