//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';

import { getChannel } from '@/helpers/channels.js';
import { channelCode } from '@motionelements/core/src/helpers/channelTypes.js';
import channelTypesMixin from '@motionelements/core/src/components/mixins/channelTypes.mixin.js';

export default {
  name: 'ChannelPageLayout',
  mixins: [channelTypesMixin],
  components: {
    BaseLayout: () => import('../components/core/BaseLayout.vue'),
    BaseBreadcrumbs: () => import('../components/core/BaseBreadcrumbs.vue'),
    NavBar: () => import('../components/core/TheNavBar.vue'),
    HomeBenefits: () => import('@/components/home-page/HomeBenefits.vue'),
    HomeFaqs: () => import('@/components/home-page/HomeFaqs.vue'),
    ChannelsHero: () => import('@/components/channels/ChannelsHero.vue'),
    LottieBenefits: () => import('@/components/channels/LottieBenefits.vue'),
    LottieGettingStarted: () => import('@/components/channels/LottieGettingStarted.vue'),
    CustomizeGif: () => import('@/components/channels/CustomizeGif.vue'),
    GifUseCases: () => import('@/components/channels/GifUseCases.vue'),
  },
  data() {
    return {
      channelCode,
    };
  },
  computed: {
    ...mapState({
      channelType: state => state.channels.channelType,
    }),
    currentChannel() {
      return getChannel(this, this.channelType);
    },
    breadcrumbList() {
      let name;
      switch (this.channelType) {
        case this.channelCode.FCP:
        case this.channelCode.MUSIC:
        case this.channelCode.LOTTIE:
          name = this.$term('mediaType', this.channelType).short().text();
          break;
        case this.channelCode.VIDEO:
          name = this.$term('mediaType', this.channelType).seo().text();
          break;
        case this.channelCode['3D']:
          name = this.$term('mediaType', '3d_model').text();
          break;
        case this.channelCode.IMAGE_PROMPT:
          name = this.$term('mediaType', this.channelType).plural().text();
          break;
        default:
          name = this.$term('mediaType', this.channelType).text();
      }

      return [
        {
          name: name,
          link: this.$link(_.get(this.getChannelTypeById(this.channelType), 'link')).fullPath(),
          active: true,
        },
      ];
    },
    benefits() {
      return _.get(this.currentChannel, 'benefits');
    },
    benefitsHeading() {
      return _.get(this.currentChannel, 'benefitsHeading');
    },
    benefitsSubHeading() {
      return _.get(this.currentChannel, 'benefitsSubHeading');
    },
  },
};
