import * as suggestionsApi from '@motionelements/core/src/api/suggestions.js';
import { listMediaTypeCategories } from '@motionelements/core/src/api/media-types.js';

const state = {
  history: [],
  typeahead: {
    terms: [],
  },
  popularCategories: [],
  // track fileSearch upload
  upload: {
    status: false,
    message: '',
    progress: 0,
  },
  mediaType: null,
};

const getters = {
  history: state => state.history,
};

const actions = {
  deleteItem({ commit }, keyword) {
    console.log('action deleteItem :', keyword);
    commit('deleteItem', keyword);
  },
  deleteAll({ commit }) {
    commit('deleteAll');
  },
  getTypeaheadSuggestions({ commit }, payload) {
    const params = {
      q: payload.q,
      mt: payload.mediaType,
    };
    console.log('typeahead params', params);
    return suggestionsApi.listSearchTypeaheadSuggestions(params)
      .then((response) => {
        // console.log(result);
        commit('setTypeaheads', response.data);
      });
  },
  getPopularCategories({ commit }, payload) {
    listMediaTypeCategories(payload.mediaType, {
      'fields[category]': 'name',
      sort: 'popular',
      perPage: 3,
      type: _.get(payload, 'type'),
    })
      .then(({ data: { data: categories } }) => {
        // commit('setPopularCategories', categories.filter(obj => obj.type === 'category'));
        commit('setPopularCategories', categories);
      });
  },
};

const mutations = {
  addHistory(state, keyword) {
    if (keyword.length && !state.history.includes(keyword)) {
      const limit = 100;
      state.history.unshift(keyword);
      state.history = state.history.slice(0, limit); // limit items
    }
  },
  deleteItem(state, keyword) {
    const itemIndex = state.history.findIndex(x => x === keyword);
    if (itemIndex > -1) {
      state.history.splice(itemIndex, 1);
    }
  },
  deleteAll(state) {
    state.history = [];
  },
  setTypeaheads(state, terms) {
    state.typeahead.terms = terms;
  },
  setPopularCategories(state, categories) {
    state.popularCategories = categories;
  },
  setUploadProgress: (state, params) => {
    state.upload.status = params.status;

    if (params.progress) {
      state.upload.progress = params.progress;
    }

    if (params.message) {
      state.upload.message = params.message;
    }
  },
  setMediaType(state, mediaType) {
    state.mediaType = mediaType;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
