import api from '@motionelements/core/src/api/base-api-v2.js';
import { snakeCaseKeys } from '@motionelements/core/src/helpers/string.js';

// eslint-disable-next-line import/prefer-default-export
// export const listMediaTypes = async () => api.request('get', '/v2/media-types', {
//   params: api.getLocaleParams(),
// });

const baseUrl = '/v2/media-types';

// eslint-disable-next-line
export const listMediaTypeCategories = (mediaType, params, axiosConfig) => {
  params = params || {};
  // mediaType = mediaType.replace('_', '-');
  params.media_type = mediaType.replace('-', '_');
  return api.get('/v2/categories', {
    ...(axiosConfig || {}),
    params: {
      ...api.getLocaleParams(),
      ...snakeCaseKeys(params),
    },
  });
};

// eslint-disable-next-line
export const listYCIDAgencies = (mediaType, axiosConfig) => {
  return api.get(`${baseUrl}/${mediaType}/content-id-agencies`, {
    ...(axiosConfig || {}),
    params: {
      type: 'youtube_content_id',
    },
  });
};

// eslint-disable-next-line
export const listPerformanceRightsOrgs = () => {
  return api.get(`${baseUrl}/music/performance-rights-organisations`, {
    params: {
      ...api.getLocaleParams(),
    },
  });
};
