import { commonLanguageLogicNuxt } from '@motionelements/core/src/services/language.service.js';

export default ({ store }) => {
  store.app.router.beforeEach((to, from, next) => {
    commonLanguageLogicNuxt({ to, from }, store.app.store.$i18n)
      .then(languageCode => {
        if (languageCode) { // lazy load language
          store.dispatch('route/setRoute', to);
          store.dispatch('route/setPreviousRoute', from);
          store.dispatch('user/setLanguage', store.app.store.$i18n.locale);
          next();
        } else {
          // redirect to another lang page
        }
      });
  });
};
