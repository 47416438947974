import cookies from 'js-cookie';
import { mapState } from 'vuex';
import { COOKIE_TESTING_KEY } from '@motionelements/core/src/app.constants.js';

export default {
  name: 'layoutsHeadMixin',
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
        class: this.isQaTokenExists ? 'is-automated-test' : '',
      },
      bodyAttrs: {
        // [] is nuxt specific, helps to unify all classes for bodyAttrs, without this each bodyAttrs will overwrite the previous ones
        class: [this.uiMode === 'dark' ? 'ui-dark' : ''],
      },
    };
  },
  computed: {
    ...mapState({
      uiMode: state => state.user.preferences.ui.mode,
    }),
    isQaTokenExists() {
      return cookies.get(COOKIE_TESTING_KEY);
    },
  },
};
