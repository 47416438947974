

export default function (to, from) {
      if (to.path === from.path) {
        return false;
      }

      const position = { x: 0, y: 0 };
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(position);
        }, 0);
      });
    }
