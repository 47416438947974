import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
  faFileDownload as farFileDownload,
  faChevronRight as farChevronRight,
} from '@fortawesome/pro-regular-svg-icons';

import { faMeImages as fasMeImages } from '@/assets/icons/me-font-icons-regular.js';

// remove when rewrite ProductCard with importing icons in component
import {
  faMe3DModel as fasMe3DModel,
  // faMeAdobePremierePro as fasMeAdobePremierePro,
  // faMeAdobePremiereRush as fasMeAdobePremiereRush,
  faMeAfterEffects as fasMeAfterEffects,
  faMeAfterEffectsSquare as fasMeAfterEffectsSquare,
  // faMeAnimation as fasMeAnimation,
  faMeAppleMotion as fasMeAppleMotion,
  // faMeCollection as fasMeCollection,
  // faMeCreditsCircle as fasMeCreditsCircle,
  faMeDavinciResolve as fasMeDavinciResolve,
  // faMeDiamond as fasMeDiamond,
  // faMeDownload as fasMeDownload,
  // faMeEasy as fasMeEasy,
  // faMeFcp as fasMeFcp,
  // faMeFcpEdit as fasMeFcpEdit,
  faMeImage as fasMeImage,
  // faMeImages as fasMeImages,
  faMeLightning as fasMeLightning,
  faMeLottie as fasMeLottie,
  faMeMogrt as fasMeMogrt,
  faMeMotionelements as fasMeMotionelements,
  faMeMusic as fasMeMusic,
  faMePremierePro as fasMePremierePro,
  // faMePlugin as fasMePlugin,
  // faMeSearch as fasMeSearch,
  // faMeSearchAi as fasMeSearchAi,
  faMeSfx as fasMeSfx,
  // faMeUserArtist as fasMeUserArtist,
  // faMeUserBuyer as fasMeUserBuyer,
  faMeVideo as fasMeVideo,
  faMeGraphicTemplate as fasMeGraphicTemplate,
  faMeUiTemplate as fasMeUiTemplate,
  faMePresentationTemplate as fasMePresentationTemplate,
  faMeNotionTemplate as fasMeNotionTemplate,
  faMePrompt as fasMePrompt,
} from '@motionelements/assets/src/js/me-font/icons/me-font-icons-solid.js';

Vue.component('font-awesome-icon', FontAwesomeIcon);

library.add(
  farFileDownload,
  farChevronRight,

  fasMe3DModel,
  // fasMeAdobePremierePro,
  // fasMeAdobePremiereRush,
  fasMeAfterEffects,
  fasMeAfterEffectsSquare,
  // fasMeAnimation,
  fasMeAppleMotion,
  // fasMeCollection,
  // fasMeCreditsCircle,
  fasMeDavinciResolve,
  // fasMeDiamond,
  // fasMeDownload,
  // fasMeEasy,
  // fasMeFcp,
  // fasMeFcpEdit,
  fasMeImage,
  fasMeImages,
  fasMeLightning,
  fasMeLottie,
  fasMeMogrt,
  fasMeMusic,
  fasMePremierePro,
  // fasMePlugin,
  // fasMeSearch,
  // fasMeSearchAi,
  fasMeSfx,
  // fasMeUserArtist,
  // fasMeUserBuyer,
  fasMeVideo,
  fasMeMotionelements,
  fasMeGraphicTemplate,
  fasMeUiTemplate,
  fasMePresentationTemplate,
  fasMeNotionTemplate,
  fasMePrompt,
);
