import Vue from 'vue';

// Nuxt compatibility workaround
// let store;
//
// // Nuxt compatibility workaround
// export function setStore(s) {
//   // if (!store) store = s;
//   store = s;
// }

// Event Bus
// https://www.digitalocean.com/community/tutorials/vuejs-global-event-bus
// https://github.com/DivanteLtd/vue-storefront/blob/master/core/compatibility/plugins/event-bus/index.js

const EventBus = new Vue();

/*
function isMember() {
  return store.getters['user/isMember'];
}

function addFavoriteItem(product, folderId) {
  console.log('addFavorites', product);
  folderId = folderId || 'default';

  store.dispatch('favorites/addFavoriteItem', {
    folderId: folderId,
    product: product,
  }).then((response) => {
    store.commit('library/updateFolder', {
      folderId,
      path: 'items.totalCount',
      value: response.data.data.totalCount,
    });
    console.log('addFavorites done', folderId, product, response);
    const data = _.get(response, 'data.data');

    // @deprecated
    if (folderId === 'default' && _.has(data, 'totalCount')) {
      store.dispatch('site/setFavoritesCount', {
        data: {
          id: folderId,
          items: {
            total_count: data.totalCount,
          },
        },
      });
    }

    const favoritesAddTimestamp = _.get(data, 'addedAt');
    const favoritesElementInfo = product;

    const productsInfo = [];
    productsInfo.push(favoritesElementInfo);

    const teleportInfo = {
      'timestamp': favoritesAddTimestamp,
      'products': productsInfo,
    };

    console.log('addFavorites teleportProductInfo', teleportInfo);

    teleport.event('product_favorited', teleportInfo, this);

  });
}

function showLoginModal() {
  store.dispatch('modal/showSignUp');
}


EventBus.$on('product-favorited', (product, folderId) => {
  // console.log('bus: product-favorited', product);
  // EventBus.$off('product-favorited');

  if (!isMember()) {
    showLoginModal();
    return;
  }

  addFavoriteItem(product, folderId);
});


const Dummy = {};
*/

// eslint-disable-next-line import/prefer-default-export
export { EventBus };
