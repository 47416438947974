import * as artistsApi from '@motionelements/core/src/api/artists.js';
// import * as formatter from '@/api/formatter';
// eslint-disable-next-line no-unused-vars
import store from '@/store/index.js';

// Nuxt compatibility workaround
// let store = vueStore;

// Nuxt compatibility workaround
export function setStore(s) {
  // if (!store) store = s;
  store = s;
}
// export async function getArtistDetails(sku, type) {
//   return elementsApi.getElementDetails(sku, type);
// };

const nonFileBasedMediaTypes = ['notion_template', 'midjourney_prompt', 'image_prompt'];
const newMediaTypes = ['graphic_template', 'ui_template', 'presentation_template', 'notion_template', 'midjourney_prompt', 'image_prompt'];

/**
 * get artists profile
 * @param {String} username
 */
export async function getArtistProfile(username) {
  return (await artistsApi.getArtist(username)).data.data;
}


// simple helpers
export function getMediaTypeGroup(mediaType) {
  switch (true) {
    case ['photo', 'vector', 'gif', 'photo_vr'].includes(mediaType):
      return 'image';
    case ['video_live', 'animation', 'video_vr'].includes(mediaType):
      return 'video';
    case ['ae_template', 'ae_preset'].includes(mediaType):
      return 'ae';
    case ['pr_template', 'pr_preset', 'mg_template'].includes(mediaType):
      return 'pr';
    case ['motion_template'].includes(mediaType):
      return 'fcp';
    case ['resolve_template', 'resolve_macro'].includes(mediaType):
      return 'resolve';
    case ['3d_model'].includes(mediaType):
      return '3d';
    case ['graphic_template', 'ui_template', 'presentation_template', 'notion_template'].includes(mediaType):
      return 'template';
    case ['midjourney_prompt', 'image_prompt'].includes(mediaType):
      return 'prompt';
    default:
      return mediaType;
  }
}

export function isProductType(productType, mediaType) {
  if (!mediaType) {
    return false;
  }

  const mediaTypeCode = mediaType.replace('-', '_');

  const productTypes = Array.isArray(productType) ? productType : [productType];

  const group = getMediaTypeGroup(mediaTypeCode);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < productTypes.length; i++) {
    const type = productTypes[i];

    if (mediaTypeCode === type || group === type) {
      return true;
    }

    switch (type) {
      case 'video_template':
        if ([
          'ae', 'ae_template', 'ae_preset',
          'pr', 'pr_template', 'pr_preset', 'mg_template',
          'fcp', 'motion_template',
          'resolve', 'resolve_template', 'resolve_macro',
        ].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case 'template':
        if (['ae', 'pr', 'fcp', 'resolve'].includes(group)
          || mediaTypeCode.endsWith('_template')) {
          return true;
        }
        break;
      case 'ae':
        if (['ae_template', 'ae_preset'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case 'pr':
        if (['pr_template', 'pr_preset', 'mg_template'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case 'fcp':
        if (['fcp', 'motion_template'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case 'resolve':
        if (['resolve_template', 'resolve_macro'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case 'video':
        if (['video', 'video_live', 'animation', 'video_vr'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case 'audio':
        if (['music', 'sfx'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case 'image':
        if (['photo', 'vector', 'gif', 'photo_vr'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case 'vr':
        if (['video_vr', 'photo_vr'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case '3d':
        if (['3d_model', '3d'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case 'notion':
        if (['notion_template'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case 'ui':
        if (['ui_template'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case 'presentation':
        if (['presentation_template'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      case 'graphic':
        if (['graphic_template'].includes(mediaTypeCode)) {
          return true;
        }
        break;
      // no default
    }
  }

  return false;
}

export function canUploadPreviewStills(mediaType) {
  return isProductType(['3d', 'vector', 'template', 'prompt', 'lottie'], mediaType);
}

export function canUploadPreviewVideo(mediaType) {
  return isProductType([
    'video_template',
    '3d',
    'lottie',
    'template',
  ], mediaType);
}

export function hasPreviewVideo(mediaType) {
  return canUploadPreviewVideo(mediaType)
  || isProductType([
    'video',
  ], mediaType);
}

export function isFileBasedMediaType(mediaType) {
  return !nonFileBasedMediaTypes.includes(mediaType);
}

export function isNewMediaType(mediaType) {
  return newMediaTypes.includes(mediaType);
}

export function getMediaTypeUploaderTypes(mediaType) {
  switch (true) {
    case mediaType === 'notion_template':
      return ['url'];
    case ['graphic_template', 'ui_template', 'presentation_template'].includes(mediaType):
      return ['file_based', 'url'];
    case mediaType === 'image_prompt':
      return 'text';
    default:
      return ['file_based'];
  }
}

export function isTransitionedProduct(mediaType) {
  return isProductType(['video', 'image', 'image_prompt', 'midjourney_prompt'], mediaType);
}
