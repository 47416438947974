const state = {
  path: '',
  fullPath: '',
  route: null,
  previousRoute: null,
};

const getters = {
  // path: () => state.path,
  // fullPath: () => state.fullPath,
  path: state => state.path,
  fullPath: state => state.fullPath,
};

const actions = {
  setRoute({ commit }, route) {
    commit('setRoute', route);
    // commit('setPath', route.path);
    // commit('setFullPath', route.fullPath);
  },
  setPreviousRoute({ commit }, route) {
    commit('setPreviousRoute', route);
  },
};

const mutations = {
  setRoute(state, route) {
    state.route = route;
    state.path = route.path;
    state.fullPath = route.fullPath;
  },

  setPreviousRoute(state, route) {
    state.previousRoute = route;
  },

  setPath(state, path) {
    state.path = path;
  },

  setFullPath(state, path) {
    state.fullPath = path;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
