import videoPlayer from './video';
import videoPlayerSettings from './videoSettings';
import audioPlayer from './audio';
// import tutorialPlayer from './tutorial';

const supportsVideoType = (type) => {
  let video;

  // Allow user to create shortcuts, i.e. just "webm"
  const formats = {
    ogg: 'video/ogg; codecs="theora"',
    h264: 'video/mp4; codecs="avc1.42E01E"',
    webm: 'video/webm; codecs="vp8, vorbis"',
    vp9: 'video/webm; codecs="vp9"',
    hls: 'application/x-mpegURL; codecs="avc1.42E01E"',
  };

  if (!video) {
    video = document.createElement('video');
  }

  return video.canPlayType(formats[type] || type);
};

const isWebm = () => (supportsVideoType('webm') ? true : false);

const generatePlaylist = (playlist) => {
  const arr = [];
  playlist.forEach((element) => {
    arr.push(isWebm() ? element.previews.webm.url : element.previews.mp4.url);
  });
  return arr;
};


const player = {
  videoPlayer,
  videoPlayerSettings,
  audioPlayer,
  // tutorialPlayer,
  supportsVideoType,
  isWebm,
  generatePlaylist,
};

export default player;
