export const isGtmEnabled = (vue) => {
  if (process.server) {
    return false;
  }

  if (typeof (vue.gtm || vue.$gtm) === 'undefined') {
    return false;
  }

  const isPrerender = process.env.VUE_APP_PRERENDER === 'true' || (navigator.userAgent || '').indexOf('HeadlessChrome') !== -1;

  const isTestingEnv = ['testing'].includes(process.env.VUE_APP_ENV);

  // disabled in Vue
  if (!process.env.VUE_APP_NUXT && !vue.$gtm.enabled()) {
    return false;
  }

  return typeof window.dataLayer !== 'undefined' && !isPrerender && !isTestingEnv;
};


export const trackGtmEvent = (vue, eventData) => {
  if (process.env.VUE_APP_NUXT) {
    // nuxt-gtm
    vue.$gtm.push(eventData);
  } else {
    // vue-gtm
    vue.$gtm.trackEvent(eventData);
  }
};
