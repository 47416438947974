import VuexPersistence from 'vuex-persist';
import { STORAGE_VUEX_FAVORITES_KEY, STORAGE_VUEX_FAVORITES_VERSION } from '@motionelements/core/src/app.constants.js';

export default new VuexPersistence({
  key: STORAGE_VUEX_FAVORITES_KEY,
  storage: window.sessionStorage,
  reducer: ({ favorites, user }) => ({
    hash: `${user.jwt.id}|${user.language}|${user.currencyCode}|${STORAGE_VUEX_FAVORITES_VERSION}`,
    favorites: {
      defaultFolder: {
        totalCount: favorites.defaultFolder.totalCount,
      },
      addedToday: favorites.addedToday,
    },
  }),
  // eslint-disable-next-line
  filter: (mutation) => {
    // if (mutation.type.split('/').includes('favorites')) console.log(mutation);
    return [
      'favorites/setDefaultFolderTotalCount',
      'favorites/updateAddedToday',
      'favorites/findAndRemoveFavoriteItemsFromAddedToday',
    ].includes(mutation.type);
  },
});
