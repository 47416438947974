//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'the-nav-bar',
  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TheNavDesktop: () => import('@motionelements/core/src/components/nav/TheNavBarDesktop.vue'),
    TheNavMobile: () => import('@motionelements/core/src/components/nav/TheNavBarMobile.vue'),
    AuthModalsWrapper: () => import('@motionelements/core/src/components/auth/AuthModalsWrapper.vue'),
  },
  created() {
    if (this.isLoggedIn) {
      this.$store.dispatch('cart/getSummary');
    }
  },
};
