//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HomeBenefits',
  props: {
    heading: String,
    subheading: String,
    benefits: Array,
  },
};
