import VuexPersistence from 'vuex-persist';

import {
  STORAGE_VUEX_NAVBAR_PREF_KEY,
  STORAGE_VUEX_NAVBAR_PREF_VERSION,
} from '@motionelements/core/src/app.constants.js';

export default new VuexPersistence({
  key: STORAGE_VUEX_NAVBAR_PREF_KEY,
  storage: window.localStorage,
  reducer: ({ user, app }) => ({
    hash: `${user.jwt.id}|${user.language}|${STORAGE_VUEX_NAVBAR_PREF_VERSION}`,
    app: {
      navbarRecentlyVisited: app.navbarRecentlyVisited,
    },
    modifiedAt: Math.round(Date.now() / 1000),
  }),
  filter: (mutation) => [
    'app/setNavbarRecentlyVisited',
    'app/addNavbarRecentlyVisited',
  ].includes(mutation.type),
});
