import api from '@motionelements/core/src/api/base-api-v2';

export const getCartSummary = async () => api.get('/v2/cart', {
  params: api.getLocaleParams(),
});

export const checkout = (payload) => {
  const url = '/v2/cart/checkout';
  return api.request('post', url, {
    data: payload,
    params: api.getLocaleParams(),
  });
};

export const listItems = async (params) => api.get('/v2/cart/items', {
  params: {
    ...api.getLocaleParams(),
    ...params,
  },
});

export const addItems = (payload, params) => {
  const url = '/v2/cart/items';
  return api.request('post', url, {
    data: payload,
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
  });
};

export const removeItem = (sku) => {
  const url = `/v2/cart/items/${sku}`;
  return api.request('delete', url, {
    // data: payload,
    params: api.getLocaleParams(),
  });
};

export const removeItems = (payload) => {
  const url = '/v2/cart/items';
  return api.request('delete', url, {
    data: payload,
    params: api.getLocaleParams(),
  });
};

export const clearCart = () => {
  const url = '/v2/cart';
  return api.request('delete', url, {
    params: api.getLocaleParams(),
  });
};

export const updateItem = (sku, payload, params) => {
  const url = `/v2/cart/items/${sku}`;
  return api.request('patch', url, {
    data: payload,
    params: {
      ...api.getLocaleParams(),
      ...params,
    },
  });
};
