import {
  channelCode,
  channelTypes,
  freeMediaTypes,
  getChannelCode,
} from '@motionelements/core/src/helpers/channelTypes.js';
import featureFlagMixin from '@motionelements/core/src/components/mixins/feature-flag.mixin.js';

export default {
  mixins: [featureFlagMixin],
  computed: {
    channelTypesFiltered() {
      return channelTypes.filter(({ isFeatureEnabled }) => {
        if (isFeatureEnabled) {
          return this.isFeatureEnabled(isFeatureEnabled);
        }
        return true;
      });
    },
    channelTypesSortedForSwiper() {
      return [
        ...this.channelTypesGroup1.slice(0, 3),
        ...this.channelTypesGroup2,
        ...this.channelTypesGroup1.slice(3, this.channelTypesGroup1.length),
      ];
    },
    channelTypesGroup1Length() {
      let channelTypesGroup1Length = 6;

      if (this.isFeatureEnabled('buyer_midjourney_prompt')) {
        channelTypesGroup1Length += 1;
      }
      if (this.isFeatureEnabled('buyer_new_media_types')) {
        channelTypesGroup1Length += 1;
      }
      return channelTypesGroup1Length;
    },
    channelTypesGroup1() {
      return this.channelTypesFiltered.slice(0, this.channelTypesGroup1Length);
    },
    channelTypesGroup2() {
      return this.channelTypesFiltered.slice(this.channelTypesGroup1Length, this.channelTypesFiltered.length);
    },
    freeMediaTypes() {
      return freeMediaTypes.filter(({ isFeatureEnabled }) => {
        if (isFeatureEnabled) {
          return this.isFeatureEnabled(isFeatureEnabled);
        }
        return true;
      });
    },
  },
  methods: {
    getChannelTypeById(channelTypeId) {
      return channelTypes.find(({ id }) => id === getChannelCode(channelTypeId));
    },
    getChannelTypeName(channelTypeId, wbr = true) {
      switch (channelTypeId) {
        case channelCode.IMAGE:
          // return this.$t('header.image');
          return this.$t('term.photo_&_gif');
        case channelCode.SFX:
          return this.$t('header.sfx');
        case channelCode.FCP:
          return this.$t('header.motion');
        case channelCode.AE:
        case channelCode.IMAGE_PROMPT:
        case channelCode.PR:
          return wbr ? this.$term('mediaType', channelTypeId).wbr().plural().html() : this.$term('mediaType', channelTypeId).plural().html();
        default:
          return wbr ? this.$term('mediaType', channelTypeId).wbr().short().plural()
            .html() : this.$term('mediaType', channelTypeId).short().plural().html();
      }
    },
    showVisualSearch(channelTypeId) {
      const channelsWithVisualSearch = [
        'video',
        'music',
        'image',
        'ae',
        'pr',
        'fcp',
        'resolve',
      ];
      return channelsWithVisualSearch.includes(channelTypeId);
    },
  },
};
