import locale77427e78 from '../../src/lang/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"code":"ja","iso":"ja","file":"ja.js"},{"code":"ko","iso":"ko","file":"ko.js"},{"code":"zh-hant","iso":"zh-Hant","file":"zh-hant.js"},{"code":"zh-hans","iso":"zh-Hans","file":"zh-hans.js"},{"code":"th","iso":"th","file":"th.js"},{"code":"de","iso":"de","file":"de.js"},{"code":"es","iso":"es","file":"es.js"},{"code":"fr","iso":"fr","file":"fr.js"},{"code":"pt","iso":"pt","file":"pt.js"},{"code":"hu","iso":"hu","file":"hu.js"},{"code":"it","iso":"it","file":"it.js"},{"code":"ru","iso":"ru","file":"ru.js"},{"code":"tr","iso":"tr","file":"tr.js"},{"code":"sv","iso":"sv","file":"sv.js"},{"code":"pl","iso":"pl","file":"pl.js"},{"code":"nb","iso":"nb","file":"nb.js"},{"code":"nl","iso":"nl","file":"nl.js"},{"code":"da","iso":"da","file":"da.js"},{"code":"en","iso":"en","file":"en.js"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/opt/render/project/src/channels2022/src/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ja","iso":"ja","file":"ja.js"},{"code":"ko","iso":"ko","file":"ko.js"},{"code":"zh-hant","iso":"zh-Hant","file":"zh-hant.js"},{"code":"zh-hans","iso":"zh-Hans","file":"zh-hans.js"},{"code":"th","iso":"th","file":"th.js"},{"code":"de","iso":"de","file":"de.js"},{"code":"es","iso":"es","file":"es.js"},{"code":"fr","iso":"fr","file":"fr.js"},{"code":"pt","iso":"pt","file":"pt.js"},{"code":"hu","iso":"hu","file":"hu.js"},{"code":"it","iso":"it","file":"it.js"},{"code":"ru","iso":"ru","file":"ru.js"},{"code":"tr","iso":"tr","file":"tr.js"},{"code":"sv","iso":"sv","file":"sv.js"},{"code":"pl","iso":"pl","file":"pl.js"},{"code":"nb","iso":"nb","file":"nb.js"},{"code":"nl","iso":"nl","file":"nl.js"},{"code":"da","iso":"da","file":"da.js"},{"code":"en","iso":"en","file":"en.js"}],
  localeCodes: ["ja","ko","zh-hant","zh-hans","th","de","es","fr","pt","hu","it","ru","tr","sv","pl","nb","nl","da","en"],
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'ja.js': () => import('../../src/lang/ja.js' /* webpackChunkName: "lang-ja.js" */),
  'ko.js': () => import('../../src/lang/ko.js' /* webpackChunkName: "lang-ko.js" */),
  'zh-hant.js': () => import('../../src/lang/zh-hant.js' /* webpackChunkName: "lang-zh-hant.js" */),
  'zh-hans.js': () => import('../../src/lang/zh-hans.js' /* webpackChunkName: "lang-zh-hans.js" */),
  'th.js': () => import('../../src/lang/th.js' /* webpackChunkName: "lang-th.js" */),
  'de.js': () => import('../../src/lang/de.js' /* webpackChunkName: "lang-de.js" */),
  'es.js': () => import('../../src/lang/es.js' /* webpackChunkName: "lang-es.js" */),
  'fr.js': () => import('../../src/lang/fr.js' /* webpackChunkName: "lang-fr.js" */),
  'pt.js': () => import('../../src/lang/pt.js' /* webpackChunkName: "lang-pt.js" */),
  'hu.js': () => import('../../src/lang/hu.js' /* webpackChunkName: "lang-hu.js" */),
  'it.js': () => import('../../src/lang/it.js' /* webpackChunkName: "lang-it.js" */),
  'ru.js': () => import('../../src/lang/ru.js' /* webpackChunkName: "lang-ru.js" */),
  'tr.js': () => import('../../src/lang/tr.js' /* webpackChunkName: "lang-tr.js" */),
  'sv.js': () => import('../../src/lang/sv.js' /* webpackChunkName: "lang-sv.js" */),
  'pl.js': () => import('../../src/lang/pl.js' /* webpackChunkName: "lang-pl.js" */),
  'nb.js': () => import('../../src/lang/nb.js' /* webpackChunkName: "lang-nb.js" */),
  'nl.js': () => import('../../src/lang/nl.js' /* webpackChunkName: "lang-nl.js" */),
  'da.js': () => import('../../src/lang/da.js' /* webpackChunkName: "lang-da.js" */),
}
